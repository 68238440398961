<div class="container general-info-page">

  <p class="MsoNormal" style="text-align:justify"><span lang="EN-US">Date Updated:&nbsp; July
1<sup>st</sup>, 2024</span></p>

  <p class="MsoNormal" style="text-align:justify"><b><span lang="EN-US">NO PURCHASE
OR PAYMENT NECESSARY TO PARTICIPATE.&nbsp; A PURCHASE OR PAYMENT OF ANY KIND WILL
NOT INCREASE A PARTICIPANT’S CHANCES OF WINNING. YOU HAVE NOT WON.</span></b></p>

  <p class="MsoNormal" style="text-align:justify"><span lang="EN-US">Baba Casino is
an online platform which offers Participants the chance to play games for
entertainment and enjoyment. We also give away sweepstakes entries referred to
as “Sweeps Coins” as set out
in these Rules.&nbsp; Sweeps Coins can be used to play sweepstakes games for chance
to win further Sweeps Coins that <b>can then later be redeemed for real prizes</b>.
Sweeps Coins can never be purchased – they are available for free as described
in these Rules. </span></p>

  <p class="MsoNormal" style="text-align:justify"><span lang="EN-US">These Rules are
to be read in conjunction with our <a href="https://www.baba-entertainment.com/play-terms-of-service">Terms of
Service</a> (“Terms”).&nbsp; Words and phrases which are defined in the Terms have
the same meaning in these Rules.</span></p>

  <p class="MsoNormal" style="text-align:justify"><span lang="EN-US">&nbsp;</span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-indent:0cm;line-height:21.0pt;background:white;vertical-align:
baseline"><b><span lang="EN-US" style="font-size:19.0pt;color:#A41EB4">1.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span dir="LTR"></span><b><span lang="EN-US" style="font-size:
19.0pt;color:#A41EB4">ELIGIBILITY TO PLAY </span></b></p>

  <p class="MsoListParagraphCxSpFirst" style="text-align:justify"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">a.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">The sweepstakes promotion
(“<b>Sweepstake</b>s”) is only open to legal residents of the United States
(including the District of Columbia but excluding outlying territories and the
States of Idaho, Michigan, Nevada, Washington, Arkansas, Georgia, Iowa, Kentucky, Nebraska, Hawaii, Mississippi, New York, North Dakota, Ohio, Tennessee, Vermont), who are at least twenty one (21) years old or the age of
majority in their jurisdiction (whichever occurs later) at the time of entry.&nbsp; <b>PARTICIPATION
IS VOID WHERE PROHIBITED BY LAW</b>.</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify"><span lang="EN-US">&nbsp;</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">b.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">A person who participates
in the Sweepstakes is a <b>Participant</b>. </span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify"><span lang="EN-US">&nbsp;</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">c.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">The Sweepstakes is subject
to all applicable federal, state, provincial, territorial, and local laws and
regulations. &nbsp;It is the sole responsibility of a Participant to determine
whether the Sweepstakes is legal and compliant with all regulations in the
jurisdiction in which the Participant resides.</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify"><span lang="EN-US">&nbsp;</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">d.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Participation constitutes
the Participant’s full and unconditional agreement to these Rules and
Sponsor/Promoter reserve the right, in our sole discretion, to limit the value of your Prize redemptions to: (a) USD$10,000 per day; and/or USD$100,000 per calendar month; and/or (b) any other amount over any time that we consider necessary to satisfy our regulatory obligations or the requirements of our partners and suppliers.</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify"><span lang="EN-US">&nbsp;</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">e.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Winning a prize is
contingent upon fulfilling all requirements set out in these Rules.</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify"><span lang="EN-US">&nbsp;</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">f.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Employees of Baba
Entertainment Limited, any of their respective affiliates, subsidiaries,
holding companies, advertising agencies, or any other company or individual
involved with the design, production, execution or distribution of the
Sweepstakes and their immediate family (spouse, parents, siblings and children,
whether the relationship is by birth, marriage or adoption) and household
members (people who share the same residence at least 3 months of the year) are
not eligible to participate.</span></p>

  <p class="MsoListParagraphCxSpLast" style="text-align:justify"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-indent:0cm;line-height:21.0pt;background:white;vertical-align:
baseline"><b><span lang="EN-US" style="font-size:19.0pt;color:#A41EB4">2.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span dir="LTR"></span><b><span lang="EN-US" style="font-size:
19.0pt;color:#A41EB4">SPONSOR/PROMOTER</span></b></p>

  <p class="MsoNormal" style="text-align:justify"><span lang="EN-US">The <b>Sponsor/Promoter</b>
of the Sweepstakes is Baba Entertainment Limited, Derech Begin 23, floor 4, Tel
Aviv, Israel.</span></p>

  <p class="MsoNormal" style="text-align:justify"><span lang="EN-US">Sponsor’s
decisions as to the operation and administration of the Sweepstakes, receipt of
entries, and the type and number of winnings/prizes are final and binding.&nbsp; </span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-indent:0cm;line-height:21.0pt;background:white;vertical-align:
baseline"><b><span lang="EN-US" style="font-size:19.0pt;color:#A41EB4">3.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span dir="LTR"></span><b><span lang="EN-US" style="font-size:
19.0pt;color:#A41EB4">HOW TO COLLECT SWEEPS COINS</span></b></p>

  <p class="MsoListParagraphCxSpFirst" style="margin-left:72.0pt;text-align:justify"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">a.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">To participate, you must
have an active Customer/User Account.&nbsp; If you do not have a Customer Account,
you can create one free of charge at&nbsp;<a href="http://babacasino.com">http://babacasino.com</a>.
Creation of a Customer Account with the ability to play with Sweeps Coins requires
you to supply your full name, residential address, date of birth, and social
security number; and may be subject to an extended player verification process.
To enter the Sweepstakes, a Participant must access our site at http://babacasino.com
and sign into their Customer Account.</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">b.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Once they have accessed our
site, there are 4 potential
ways for a Participant in the United States (including the District of Columbia
but excluding outlying territories and the States of Idaho, Michigan, Nevada
and Washington) and Canada (excluding the province of Quebec) to collect Sweeps
Coins:</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:108.0pt;text-align:justify"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:108.0pt;text-align:justify;
text-indent:-108.0pt"><b>i. Receive Sweeps Coins as a free bonus when
    purchasing Baba Coins.</b> Each
Participant may receive Sweeps Coins as a bonus upon the purchase of
specifically marked packs of Baba Coins.&nbsp; Baba Coins can be used for social
game play on our site. &nbsp;Baba Coins cannot be redeemed for prizes. Baba Coins
can only be redeemed for more Baba Coins, which can be used for additional play
(but no prizes). The number of&nbsp;Sweeps Coins&nbsp;a Participant will
receive as a bonus for each relevant Baba Coins purchase will be stated in the
website purchase window. &nbsp;All purchases of Baba Coins are final, and no refunds
will be given. It is not possible to only purchase Sweeps Coins, they can only
be obtained as a bonus via a Baba Coins package, and through the other methods
as stated below.</p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:108.0pt;text-align:justify;
text-indent:-108.0pt"><span lang="EN-US"><span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>ii.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span dir="LTR"></span><b><span lang="EN-US">Receive Sweeps Coins by logging into your
account.&nbsp; Participants can receive Sweeps Coins on a daily basis by taking the
following Steps (Daily Bonus):</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:144.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">1.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Log into your Account;</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:144.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">2.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Once a day the “Coin Vault
Daily Bonus” popup will appear and grant the user with Baba Coins and from time
to time also a Free Sweep Coins;</span></p>

  <p class="MsoListParagraphCxSpLast" style="margin-left:144.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">3.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Click the “Collect” button on
the Coin Vault Daily Bonus" Popup to collect the Free Prizes</span></p>

  <p class="MsoNormal" style="text-align:justify"><span lang="EN-US">Participants can
only claim a Daily Bonus once per day (the daily clock reset time is set to 6AM
on Player's Timezone..</span></p>

  <p class="MsoListParagraphCxSpFirst" style="margin-left:108.0pt;text-align:justify;
text-indent:-108.0pt"><span lang="EN-US"><span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>iii.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span dir="LTR"></span><b><span lang="EN-US">Receive Sweeps Coins by sending a request by
mail</span></b><span lang="EN-US">.&nbsp; Participants can receive Sweeps Coins by accessing
the Sweepstakes Code Generation page on the online platform and generating a code via the Code Generation button, to
reveal a thirteen (13) character</span> unique code. New codes
can be generated every few minutes.</p>

  <p class="MsoListParagraphCxSpMiddle" dir="RTL" style="margin-top:0cm;margin-right:
108.0pt;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;direction:rtl;
unicode-bidi:embed"><b><span lang="HE" style="font-size:11.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:108.0pt;text-align:justify"><span lang="EN-US">Participants must then send a standard unruled white post card or
piece of white unruled paper (<b>Request Card</b>) which must be at least 4” x
6”, unfolded, and placed inside a stamped envelope addressed, to the following
address and satisfying the requirements set out below:</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:144.0pt;text-align:justify"><span lang="EN-US">&nbsp;</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:144.0pt;text-align:justify"><span lang="EN-US">Baba Entertainment Ltd</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:144.0pt;text-align:justify"><span lang="EN-US">Baba Casino</span></p>

  <p class="MsoListParagraphCxSpLast" style="margin-left:144.0pt;text-align:justify"><span lang="EN-US">PO Box 8211</span></p>
  <p class="MsoListParagraphCxSpLast" style="margin-left:144.0pt;text-align:justify"><span lang="EN-US">Manchester NH 03108</span></p>

  <p class="MsoNormal" style="text-align:justify">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Participants
must then </p>

  <p class="MsoListParagraphCxSpFirst" style="margin-left:144.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">1.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Write the words:
“Sweepstakes Entry” on the top left corner of the envelope in addition to their
return address and the website URL; and</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:144.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">2.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Write all of the following
on only one side of the Request Card inserted inside the envelope:</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:180.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">a.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Their full name as shown on
their government issued identification;</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:180.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">b.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Their player identification
number;</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:180.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">c.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">The email address
registered to their Account;</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:180.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">d.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">The SC Request Code; </span></p>

  <p class="MsoListParagraphCxSpLast" style="margin-left:180.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">e.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">The following statement:</span></p>

  <p class="MsoNormal" style="text-align:justify"><span lang="EN-US">“I am requesting
Sweeps Coins from Baba Entertainment Ltd to participate in promotional
sweepstakes activities. In doing so, I agree to adhere to and be bound by Baba’s
Terms of Service and the Sweepstakes Rules.”</span></p>

  <p class="MsoNormal" style="margin-left:108.0pt;text-align:justify"><span lang="EN-US">For each Request Card a Participant submits in accordance with the
above requirements, the Participant will receive 4 <a>COINS</a></span><span lang="EN-US">.&nbsp; The Sweeps
Coins will be added to the Participant’s Customer Account.&nbsp; <b>NOTE: A
Participant must ensure that their handwriting is legible</b>. &nbsp;If the
Participant’s handwriting is not legible, the entry will be void and the Sweeps
Coins will <i>not</i> be credited to the Participant’s Account. The legibility
of a Participant’s handwriting shall be determined by Sponsor/Promoter in its
sole discretion.&nbsp; <b>Requests made by any other individual or any entity
(including but not limited to commercial sweepstakes subscription notification
and/or entering services) or posted from a State or Province different to the
Participant’s verified residential address will be declared invalid and Sweeps
Coins will not be credited to the Participant’s Account. &nbsp;Tampering with the
entry process or the operation of the Sweepstakes, including but not limited to
the use of any device to automate the Sweeps Coins request/entry process, is
prohibited and any requests/entries deemed by Sponsor/Promoter, in its sole
discretion, to have been submitted in this manner will be void. &nbsp;In the event a
dispute regarding the identity of the individual who actually submitted a
request or whether a request satisfies the requirements set out above cannot be
resolved to Sponsor/Promoter’s satisfaction, the affected request/entry will be
deemed ineligible.</b></span></p>

  <p class="MsoNormal" style="margin-left:108.0pt;text-align:justify"><b><span lang="EN-US">There is a limit of one Request Card per outer envelope.</span></b></p>

  <p class="MsoNormal" style="margin-left:108.0pt;text-align:justify"><span lang="EN-US">The number of Sweeps Coins a Participant has will be displayed in
their Account on the website.&nbsp; The number of Sweeps Coins to be allocated to
Participants can be changed at any time by the Sponsor/Promoter in its sole
discretion.</span></p>

  <p class="MsoNormal" style="margin-left:108.0pt;text-align:justify"><span lang="EN-US">Sponsor/Promoter is not responsible for lost, late, incomplete,
invalid, unintelligible, or misdirected Sweeps Coins requests or allocations.</span></p>

  <p class="MsoNormal" style="text-align:justify"><span lang="EN-US">In the event of
a dispute as to any registration of an Account, the authorized account holder
of the email address used to register the Account will be deemed to be the
Participant.&nbsp; The “authorized account holder” is the natural person assigned
the email address by an internet access provider, online service provider or
other organization responsible for assigning email addresses for the domain
associated with the submitted address.</span></p>

  <p class="MsoNormal" style="text-align:justify"><span lang="EN-US">Use of any
automated or other system(s) to Participate, to acquire Sweeps Coins or play
the games is prohibited and will result in disqualification and loss of
eligibility to Participate in the games. </span></p>

  <p class="MsoNormal" style="text-align:justify"><span lang="EN-US">Sweeps Coins are
only valid for sixty (60) days from the date a Participant last logged on to
their Account and will then automatically expire.</span></p>

  <p class="MsoNormal" style="text-align:justify">Sweeps Coins may
also be forfeited if a Participant’s Account is deactivated for any reason</p>

  <p class="MsoNormal" style="margin-left:36.0pt;text-indent:36.0pt">iv. <b>Receive
    2 Sweeps Coins as a Welcome Bonus</b>. New Participants signing up for the
    first time are eligible for a one-time bonus of 2 Sweeps Coins. To claim this
    Welcome Bonus, Participants must complete the following steps:</p>

  <p class="MsoNormal">&nbsp;</p>

  <p class="MsoNormal">Sign up for an Account;</p>

  <p class="MsoNormal" style="text-align:justify">The 2 Sweeps Coins will be
    credited automatically to their Account upon successful verification.</p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-indent:0cm;line-height:21.0pt;background:white;vertical-align:
baseline"><b><span lang="EN-US" style="font-size:19.0pt;color:#A41EB4">4.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span dir="LTR"></span><b><span lang="EN-US" style="font-size:
19.0pt;color:#A41EB4">USING SWEEPS COINS TO PLAY GAMES</span></b></p>

  <p class="MsoListParagraphCxSpFirst" style="margin-left:72.0pt;text-align:justify"><span lang="EN-US">&nbsp;</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">a.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Participants with Sweeps
Coins can use those Sweeps Coins to play games within our site for a chance to
win additional Sweeps Coins. &nbsp;Sweeps Coins won through game play can be
redeemed for real prizes.</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify"><span lang="EN-US">&nbsp;</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">b.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Within our site there are
different games.&nbsp; The number of&nbsp;Sweeps Coins required to play each game
will be detailed next to the game.&nbsp; A Participant may be able to increase the number
of&nbsp;Sweeps Coins they play the game with so they can increase their
potential winning eligibility.</span></p>

  <p class="MsoListParagraphCxSpMiddle"><span lang="EN-US">&nbsp;</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">c.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Only games played with Sweeps
Coins provide the opportunity to <b>redeem winnings for real prizes</b>.&nbsp; The
prize that can be won while playing a game will be shown in our site by
clicking the 'Settings’ button.</span></p>

  <p class="MsoListParagraphCxSpMiddle"><span lang="EN-US">&nbsp;</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">d.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Each Sweeps Coins that has
been won through game play (rather than collected using one of the methods
described in Section 3 above) can be redeemed <b>for a prize with value being
equivalent</b> to $1 USD.</span></p>

  <p class="MsoListParagraphCxSpMiddle"><span lang="EN-US">&nbsp;</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">e.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Sponsor’s/Promoter’s
decisions as to the administration and operation of the Sweepstakes, the game
and the amount of winnings are final and binding.</span></p>

  <p class="MsoListParagraphCxSpMiddle"><span lang="EN-US">&nbsp;</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">f.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Unless Sponsor/Promoter
requires otherwise, any Sweeps Coins allocated to a Participant are required to
be played once before they are eligible to be redeemed for prizes.&nbsp;
Sponsor/Promoter may, in its sole discretion, require that any Sweeps Coins
allocated to a Participant must be played a greater number of times (not
exceeding 20) in any combination of Sweeps Coins games before they are eligible
to be redeemed for prizes.</span></p>

  <p class="MsoListParagraphCxSpMiddle"><span lang="EN-US">&nbsp;</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">g.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Sponsor/Promoter reserves
the right to change the prize win rates and odds of any of the Sweepstake at
any time.&nbsp; A Participant can see the actual and any amended details by clicking
on the ‘Settings’ button.&nbsp; It is a Participant’s responsibility to check the
prize win rate on each occasion before they participate.</span></p>

  <p class="MsoListParagraphCxSpLast" style="text-align:justify"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-indent:0cm;line-height:21.0pt;background:white;vertical-align:
baseline"><b><span lang="EN-US" style="font-size:19.0pt;color:#A41EB4">5.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span dir="LTR"></span><b><span lang="EN-US" style="font-size:
19.0pt;color:#A41EB4">VERIFICATION AND CONFIRMATION OF POTENTIAL WINNERS</span></b></p>

  <p class="MsoListParagraphCxSpFirst" style="margin-left:72.0pt;text-align:justify"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">a.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><b><span lang="EN-US">POTENTIAL SWEEPSTAKES
WINNERS ARE SUBJECT TO VERIFICATION BY SPONSOR/PROMOTER (IN ANY MANNER IT MAY
CHOOSE) AND THE DECISIONS OF SPONSOR/PROMOTER ARE FINAL AND BINDING IN ALL
MATTERS RELATED TO THE SWEEPSTAKES. &nbsp;A PARTICIPANT IS NOT A WINNER OF ANY
PRIZE, EVEN IF THE ONLINE SCREEN INDICATES THEY ARE, UNLESS AND UNTIL THE
PARTICIPANT’S ELIGIBILITY AND THE POTENTIAL WINNING PLAY HAS BEEN VERIFIED AND
THE PARTICIPANT HAS FULLY COMPLIED WITH THESE RULES AND BEEN NOTIFIED THAT
VERIFICATION IS COMPLETE. &nbsp;SPONSOR/PROMOTER WILL NOT ACCEPT SCREEN SHOTS OR
OTHER PURPORTED EVIDENCE OF WINNING IN LIEU OF ITS VALIDATION PROCESS.</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">b.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Potential prize winners
must comply with these Rules and winning is contingent upon fulfilling all
requirements.</span></p>

  <p class="MsoListParagraphCxSpMiddle"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">c.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">A potential winner may be
required to sign and return to Sponsor/Promoter, an affidavit/declaration of
eligibility, and liability/publicity release (except where prohibited) in order
to claim his/her prize (if applicable). &nbsp;In addition, in the case of a <a>Canadian
eligible winner</a></span><span lang="EN-US">, he/she
will be required to correctly answer a mathematical skill-testing question
without mechanical or other aid to be administered at a pre-arranged mutually
convenient time.</span></p>

  <p class="MsoListParagraphCxSpMiddle"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">d.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">A potential winner may also
be required to complete an extended verification which may include supplying to
Sponsor a copy of their government-issued identification and complete additional
required verification. </span></p>

  <p class="MsoListParagraphCxSpMiddle"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">e.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">If a potential winner cannot
be contacted, fails to properly execute and return the affidavit/declaration of
eligibility and liability/publicity release within the required time period (if
applicable), fails to comply with these Rules (including, but not limited to,
the requirement to correctly answer the mathematical skill-testing question if
a resident of Canada), or if the prize or prize notification is returned as
undeliverable, that potential winner forfeits the prize.</span></p>

  <p class="MsoListParagraphCxSpMiddle"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">f.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">If a potential winner of a
Sweepstakes prize is disqualified for any reason, Sponsor/Promoter may, in its
sole discretion, reseed the prize back into the Sweepstakes.</span></p>

  <p class="MsoListParagraphCxSpLast" style="text-align:justify"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-indent:0cm;line-height:21.0pt;background:white;vertical-align:
baseline"><b><span lang="EN-US" style="font-size:19.0pt;color:#A41EB4">6.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span dir="LTR"></span><b><span lang="EN-US" style="font-size:
19.0pt;color:#A41EB4">PRIZES</span></b></p>

  <p class="MsoListParagraphCxSpFirst" style="margin-left:72.0pt;text-align:justify"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">a.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">A Participant’s Sweeps
Coins balance is shown in the Participant’s Account. </span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">b.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Where a Participant</span><span lang="EN-US" style="font-size:11.0pt;line-height:107%;font-family:&quot;Calibri&quot;,sans-serif">
</span><span lang="EN-US">has chosen to redeem prizes won in Sweeps Coins play for
cash, the cash payment will be paid in USD to the financial account or online
wallet from which you purchased Baba Coins (if you made such a purchase) or if
this is not technically possible, then to an alternative bank account you
nominate.&nbsp; Sponsor/Promoter reserves the right to require the use of the same
payment method for redemption of prizes as was used to purchase Baba Coins, or
a specific payment method at Sponsor/Promoter’s discretion. </span></p>

  <p class="MsoListParagraphCxSpMiddle"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">c.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Where a Participant has
chosen to redeem prizes for gift cards, the gift cards will be allocated to the
email address that the Participant has registered against their Account.</span></p>

  <p class="MsoListParagraphCxSpMiddle"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">d.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Sponsor/Promoter will only
process a prize redemption if the redeemed value of the prize is $100 USD or
more (i.e., a minimum of 100 eligible Sweeps Coins). Consequently, a
Participant’s Account must have a balance of at least 100 Sweeps Coins before
requesting a prize redemption.</span></p>

  <p class="MsoListParagraphCxSpMiddle"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">e.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">TO BE ELIGIBLE FOR A SWEEPS
COINS PRIZE OR A SWEEPS COINS PRIZE REDEMPTION:</span></p>

  <p class="MsoListParagraphCxSpMiddle"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:108.0pt;text-align:justify;
text-indent:-108.0pt"><span lang="EN-US"><span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>i.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span dir="LTR"></span><span lang="EN-US">A PARTICIPANT MUST BE A LEGAL RESIDENT OF THE
UNITED STATES (INCLUDING THE DISTRICT OF COLUMBIA BUT EXCLUDING OUTLYING
TERRITORIES AND THE STATES OF IDAHO, MICHIGAN, NEVADA, WASHINGTON, ARKANSAS, GEORGIA, IOWA, KENTUCKY, NEBRASKA, HAWAII, MISSISSIPPI, NEW YORK, NORTH DAKOTA, OHIO, TENNESSEE, VERMONT); AND</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:108.0pt;text-align:justify;
text-indent:-108.0pt"><span lang="EN-US"><span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>ii.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span dir="LTR"></span><span lang="EN-US">THE PARTICIPANT’S DETAILS MUST MATCH THOSE OF
THE PARTICIPANT’S ACCOUNT.</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">f.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">In Florida, the
maximum redemption value of a Sweeps Coins prize won on any one spin or play,
via a Participant’s participation in the Sweepstakes is $5,000 USD. &nbsp;Any
redemption of a prize valued in excess of $5,000 USD will not be allocated or
paid.</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle"><span lang="EN-US">Sponsor/Promoter reserve
the right, in our sole discretion, to limit the value of your Prize redemptions
to: (a) USD$10,000 per day; (b) USD$100,000 per month; or (c) any other amount over any time that we
consider necessary to satisfy our regulatory obligations or the requirements of
our partners and suppliers.</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">g.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Sponsor/Promoter is not
responsible for any taxes or fees associated with a prize redemption.&nbsp; <b>Participants
are responsible for all applicable taxes and fees associated with prize receipt
and/or redemption</b>.</span></p>

  <p class="MsoListParagraphCxSpMiddle"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">h.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Sponsor/Promoter is not
responsible for any foreign exchange transaction fees, charges or related costs
that may be incurred as a result of, or in relation to, a prize redemption,
including but not limited to any losses or additional costs arising from
foreign exchange fluctuations.</span></p>

  <p class="MsoListParagraphCxSpMiddle"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">i.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><b><span lang="EN-US">Sweeps Coins are not transferable,
</span></b><span lang="EN-US">and no substitution will be made except as provided
herein at the Sponsor’s/Promoter’s sole discretion.&nbsp; Sponsor/Promoter reserves
the right to substitute the listed prize of equal or greater value for any
reason owing to circumstances outside Sponsor’s/Promoter’s reasonable control.</span></p>

  <p class="MsoListParagraphCxSpMiddle"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">j.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Prize winners will be
notified by through email message.</span></p>

  <p class="MsoListParagraphCxSpLast" style="text-align:justify"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-indent:0cm;line-height:21.0pt;background:white;vertical-align:
baseline"><b><span lang="EN-US" style="font-size:19.0pt;color:#A41EB4">7.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span dir="LTR"></span><b><span lang="EN-US" style="font-size:
19.0pt;color:#A41EB4">ENTRY CONDITIONS AND RELEASE</span></b></p>

  <p class="MsoNormal" style="text-align:justify"><span lang="EN-US">By
participating, each Participant agrees to:</span></p>

  <p class="MsoListParagraphCxSpFirst" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">a.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Comply with and be bound
by:</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:108.0pt;text-align:justify"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:108.0pt;text-align:justify;
text-indent:-108.0pt"><span lang="EN-US"><span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>i.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span dir="LTR"></span><span lang="EN-US">The Terms;</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:108.0pt;text-align:justify;
text-indent:-108.0pt"><span lang="EN-US"><span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>ii.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span dir="LTR"></span><span lang="EN-US">These Rules; and </span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:108.0pt;text-align:justify;
text-indent:-108.0pt"><span lang="EN-US"><span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>iii.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span dir="LTR"></span><span lang="EN-US">The decisions of the Sponsor/Promoter which are
binding and final;</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">b.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Release and hold harmless
the Sponsor/Promoter and its parent, subsidiary, and affiliated companies, the
prize suppliers and any other organizations responsible for sponsoring,
fulfilling, administering, advertising or promoting the Sweepstakes, and all of
their respective past and present officers, directors, employees, agents and
representatives (collectively, the&nbsp;<b>Released Parties</b>) from and
against any and all claims, expenses, and liability, including but not limited
to negligence and damages of any kind to persons and property, including but
not limited to invasion of privacy (under appropriation, intrusion, public
disclosure of private facts, false light in the public eye or other legal
theory), defamation, slander, libel, violation of right of publicity,
infringement of trademark, copyright or other intellectual property rights,
property damage, or death or personal injury arising out of or relating to a
Participant’s entry, creation of an entry or submission of an entry,
participation in the Sweepstakes, acceptance or use or misuse of prizes
(including any travel or activity related thereto) and/or the broadcast,
exploitation or use of entry; and</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">c.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Indemnify, defend, and hold
harmless the Sponsor/Promoter from and against any and all claims, expenses,
and liabilities (including reasonable attorneys/legal fees) arising out of or
relating to a Participant’s participation or inability to participate in the
Sweepstakes and/or Participant’s acceptance, use or misuse of prizes.</span></p>

  <p class="MsoListParagraphCxSpLast" style="text-align:justify"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-indent:0cm;line-height:21.0pt;background:white;vertical-align:
baseline"><b><span lang="EN-US" style="font-size:19.0pt;color:#A41EB4">8.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span dir="LTR"></span><b><span lang="EN-US" style="font-size:
19.0pt;color:#A41EB4">PUBLICITY</span></b></p>

  <p class="MsoNormal" style="text-align:justify"><span lang="EN-US">Except where
prohibited, participation in the Sweepstakes constitutes each Participant’s
consent to Sponsor’s/Promoter’s and its agents’ use of the Participant’s name,
likeness, photograph, voice, opinions, and/or hometown and
state/province/territory for promotional purposes in any media, worldwide,
without further payment, notice, or consideration.</span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-indent:0cm;line-height:21.0pt;background:white;vertical-align:
baseline"><b><span lang="EN-US" style="font-size:19.0pt;color:#A41EB4">9.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><span dir="LTR"></span><b><span lang="EN-US" style="font-size:
19.0pt;color:#A41EB4">GENERAL CONDITIONS</span></b></p>

  <p class="MsoListParagraphCxSpFirst" style="margin-left:72.0pt;text-align:justify"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">a.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Sponsor/Promoter reserves
the right to cancel, suspend and/or modify the Sweepstakes or these Rules, or
any part of the Sweepstakes or these Rules, with immediate effect owing to circumstances
outside its reasonable control and only where circumstances make it unavoidable
if any fraud, technical failures or any other factor beyond
Sponsor’s/Promoter’s reasonable control impairs the integrity or proper
functioning of the Sweepstakes, as determined by Sponsor/Promoter in its sole
discretion.</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">b.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Sponsor/Promoter reserves
the right in its sole discretion to disqualify any individual it finds to be
tampering with the entry process or the operation of the Sweepstakes or to be
acting in violation of these Rules or any other promotion or in an
unsportsmanlike or disruptive manner.</span></p>

  <p class="MsoListParagraphCxSpMiddle"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">c.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">Any attempt by any person
to deliberately undermine the legitimate operation of the Sweepstakes may be a
violation of criminal and civil law and, should such an attempt be made,
Sponsor/Promoter reserves the right to seek damages from any such person to the
fullest extent permitted by law. &nbsp;Sponsor’s/Promoter’s failure to enforce any
term of these Rules shall not constitute a waiver of that provision.</span></p>

  <p class="MsoListParagraphCxSpMiddle"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">d.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">In all other cases, the
Sponsor/Promoter reserves the right to cancel, suspend and/or modify the
Sweepstakes. &nbsp;Any notice regarding cancellation, suspension and/or modification
will be posted on our site at <a href="http://babacasino.com">http://babacasino.com</a> the “Events feed” that located on the
top bar.</span></p>

  <p class="MsoListParagraphCxSpMiddle"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">e.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">In the event of modifying
the Sweepstakes, a Participant’s continued enrollment and/or participation in
the Sweepstakes constitutes acceptance of the modified terms.</span></p>

  <p class="MsoListParagraphCxSpLast" style="text-align:justify"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-indent:0cm;line-height:21.0pt;background:white;vertical-align:
baseline"><b><span lang="EN-US" style="font-size:19.0pt;color:#A41EB4">10.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span dir="LTR"></span><b><span lang="EN-US" style="font-size:19.0pt;color:#A41EB4">LIMITATIONS
OF LIABILITY</span></b></p>

  <p class="MsoNormal" style="text-align:justify"><span lang="EN-US">Insofar as
permitted by law in the respective territories, the Released Parties (as
defined above) are not responsible for:</span></p>

  <p class="MsoListParagraphCxSpFirst" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">a.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">any incorrect or inaccurate
information, whether caused by a Participant, printing error or by any of the
equipment or programming associated with or utilized in the Sweepstakes;</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">b.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">technical failures of any
kind, including, but not limited to malfunctions, interruptions, or
disconnections in phone lines or network hardware or software;</span></p>

  <p class="MsoListParagraphCxSpMiddle"><b><span lang="EN-US">&nbsp;</span></b></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">c.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">unauthorized human
intervention in any part of the entry process or the Sweepstakes;</span></p>

  <p class="MsoListParagraphCxSpMiddle"><span lang="EN-US">&nbsp;</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">d.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">technical or human error
which may occur in the administration or operation of the Sweepstakes; or</span></p>

  <p class="MsoListParagraphCxSpMiddle"><span lang="EN-US">&nbsp;</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-left:72.0pt;text-align:justify;
text-indent:-18.0pt"><span lang="EN-US">e.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US">any injury or damage to
persons or property which may be caused, directly or indirectly, in whole or in
part, from the Participant’s participation in the Sweepstakes or receipt or use
or misuse of any prize.</span></p>

  <p class="MsoListParagraphCxSpLast"><span lang="EN-US">&nbsp;</span></p>

  <p class="MsoNormal" style="text-align:justify"><span lang="EN-US">If for any
reason a Participant’s Sweeps Coins or winning</span><span lang="EN-US" style="font-size:11.0pt;line-height:107%;font-family:&quot;Calibri&quot;,sans-serif"> </span><span lang="EN-US">play is confirmed to have been erroneously deleted, lost, or
otherwise destroyed or corrupted, Participant’s sole remedy is replacement by
Sponsor/Promotor of an equivalent amount of&nbsp;Sweeps Coins.</span></p>

  <p class="MsoNormal" style="text-align:justify"><span lang="EN-US">&nbsp;</span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-indent:0cm;line-height:21.0pt;background:white;vertical-align:
baseline"><b><span lang="EN-US" style="font-size:19.0pt;color:#A41EB4">11.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span dir="LTR"></span><b><span lang="EN-US" style="font-size:19.0pt;color:#A41EB4">DISPUTE
RESOLUTION AND AGREEMENT TO ARBITRATE</span></b></p>

  <p class="MsoNormal" style="text-align:justify"><span lang="EN-US">All disputes are
subject to the dispute resolution provisions in our <a href="https://www.baba-entertainment.com/play-terms-of-service">Terms</a>. </span></p>

  <p class="MsoNormal" style="text-align:justify"><span lang="EN-US">To ensure fairness
and the integrity of the promotion to all Participants, the Sponsor/Promoter
will respond to questions sent to Customer
Support via <a href="https://www.baba-entertainment.com/contact-us">this form</a>.</span></p>

  <p class="MsoNormal" style="text-align:justify"><span lang="EN-US">Any Participant posting
or seen to be posting comments on Sponsor’s/Promoter’s Facebook page or
elsewhere during the promotion that are considered bullying, spiteful or
upsetting to other Participants, players, and fans of Baba or directly aimed at
the Sponsor/Promoter, will have their comments removed and will be disqualified
from the Sweepstakes. &nbsp;The Sponsor/Promoter reserves the right to alert
Facebook to any such behavior.</span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-indent:0cm;line-height:21.0pt;background:white;vertical-align:
baseline"><b><span lang="EN-US" style="font-size:19.0pt;color:#A41EB4">12.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span dir="LTR"></span><b><span lang="EN-US" style="font-size:19.0pt;color:#A41EB4">PARTICIPANT’S
PERSONAL INFORMATION</span></b></p>

  <p class="MsoNormal" style="text-align:justify"><span lang="EN-US">Information
collected from Participants is subject to Sponsor’s/Promoter’s Privacy Policy
which is available <a href="https://www.baba-entertainment.com/play-privacy-policy">here</a></span><span lang="EN-US">.</span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;text-indent:0cm;line-height:21.0pt;background:white;vertical-align:
baseline"><b><span lang="EN-US" style="font-size:19.0pt;color:#A41EB4">13.<span style="font:7.0pt ;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span dir="LTR"></span><b><span lang="EN-US" style="font-size:19.0pt;color:#A41EB4">QUESTIONS</span></b></p>

  <p class="MsoNormal" style="text-align:justify"><span lang="EN-US">If you have
questions, you may contact us at <a href="mailto:support@babacasino.com">support@babacasino.com</a>.</span></p>

</div>
