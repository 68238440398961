<div class="container general-info-page">

  <h2>Baba Entertainment Ltd.’s Terms of Service</h2>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;color:#040101">Welcome to&nbsp;</span><b><span lang="EN-US" style="font-size:12.0pt;color:#0E76A8;
border:none windowtext 1.0pt;padding:0cm">Baba Entertainment</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">, a social gaming platform (the&nbsp;</span><b><span lang="EN-US" style="font-size:12.0pt;color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">"Service"</span></b><span lang="EN-US" style="font-size:
12.0pt;,serif;color:#040101">). The Service is
owned and operated by&nbsp;</span><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Baba Entertainment Ltd. ("Baba Entertainment",
"we", "us" and "our").</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">&nbsp;It is available through our website&nbsp;</span><span lang="EN-US" style="color:black"><a href="https://www.baba-entertainment.com/www.baba-entertainment.com"><span style="font-size:12.0pt;,serif;color:#007BFF;
border:none windowtext 1.0pt;padding:0cm">www.baba-entertainment.com</span></a></span><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">&nbsp;(the&nbsp;</span><b><span lang="EN-US" style="font-size:
12.0pt;color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">"Website"</span></b><span lang="EN-US" style="font-size:
12.0pt;,serif;color:#040101">), various social
networks websites and our designated mobile and Smart TV application (the&nbsp;</span><b><span lang="EN-US" style="font-size:12.0pt;color:#0E76A8;
border:none windowtext 1.0pt;padding:0cm">"App"</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">).</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;color:#040101">Please read the following Terms of Use
(the&nbsp;</span><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;padding:0cm">"Terms"</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">) carefully. These Terms form a binding agreement between you
and Baba Entertainment and govern your use of its Services, all information,
games, accounts, multimedia, comments, and other information and/or other
content (collectively, “Content”). By installing, signing up to, accessing,
using or sending Content on the Service, you agree to these Terms. If you do
not agree to these Terms, you may not access or use the Service.</span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">Use of the Service may be
subject to additional terms and conditions that govern the use of Apple
devices, application marketplaces or other services that interact with the
Service. You alone are responsible for complying with such additional terms and
conditions.</span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">For the avoidance of doubt,
while some of the Services’ games may bear resemblance to slot-machines in
real-world casinos, the games in no way offer real money gambling
opportunities. No real money is required to play any games on the Website or
App and the Services are intended for entertainment purposes only. <b>No
purchase is necessary to enter and play games.</b></span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline">Furthermore, these Terms
expressly incorporate and are inseparably linked to our <a href="https://www.baba-entertainment.com/play-privacy-policy">Privacy Policy</a>, Baba Entertainment’s Official Sweepstakes Rules,
Legal Notices, and <a href="https://www.baba-entertainment.com/play-responsible-play">Responsible Social Gameplay Policy</a>
  </p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">Baba Entertainment Sweeps
play is only available to participants in the United States (excluding the
states of Idaho, Michigan, Nevada, Washington, Arkansas, Georgia, Iowa, Kentucky, Nebraska, Hawaii, Mississippi, New York, North Dakota, Ohio, Tennessee, Vermont). </span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><b><u><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#212529">IMPORTANT INFORMATION ABOUT
BINDING AND FINAL ARBITRATION</span></u></b></p>

  <p class="MsoNormal" style="text-align:justify"><span lang="EN-US" style="font-size:12.0pt;line-height:107%;,serif">THESE
TERMS CONTAIN A MANDATORY ARBITRATION PROVISION IN THE SECTION TITLED “DISPUTE RESOLUTION BY BINDING ARBITRATION AGREEMENT,”
WHICH SAYS THAT YOU AND BABA ENTERTAINMENT AGREE TO RESOLVE DISPUTES SOLELY
THROUGH FINAL AND BINDING ARBITRATION ON AN INDIVIDUAL AND NOT CLASS-WIDE OR
CONSOLIDATED BASIS. AMONG OTHER THINGS, THIS MEANS YOU WAIVE THE RIGHT TO
RESOLVE DISPUTES IN COURT OR BEFORE A JURY. THESE TERMS ALSO CONTAIN A
LIMITATION ON THE TIME PERIOD YOU HAVE A RIGHT TO BRING A CLAIM AGAINST BABA
ENTERTAINMENT AFTER THE RELEVANT EVENTS HAVE OCCURRED. </span></p>

  <p class="MsoNormal" style="text-align:justify"><span lang="EN-US" style="font-size:12.0pt;line-height:107%;,serif">&nbsp;</span></p>

  <p class="MsoNormal" style="text-align:justify"><span lang="EN-US" style="font-size:12.0pt;line-height:107%;,serif">YOU
HAVE THE RIGHT TO OPT OUT OF ARBITRATION. SEE THE SECTION TITLED “DISPUTE
RESOLUTION BY BINDING ARBITRATION AGREEMENT” BELOW FOR INSTRUCTIONS ON HOW TO
OPT OUT.</span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:21.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:19.0pt;,serif;
color:#A41EB4">ACCESS TO THE SERVICE</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;color:#040101">Use of the Service is intended and
permitted for persons 21 years of age or older. By using, accessing or
registering with the Service you hereby declare that you are 21 years of age or
older.&nbsp;</span><b><span lang="EN-US" style="font-size:12.0pt;font-family:
&quot;inherit&quot;,serif;color:#0E76A8;border:none windowtext 1.0pt;padding:0cm">IF YOU
ARE UNDER THE AGE OF 21 YOU MAY NOT USE OR ACCESS THE SERVICE IN ANY WAY.</span></b></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">We reserve the right to
terminate an account, including if we find that your age is less than the
minimum age. We may request additional information to confirm your age at any
time. Any participant found to be under the minimum age will have their account
terminated and their Games’ records will be void.</span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">PLEASE NOTE THAT IT IS YOUR
SOLE RESPONSIBILITY TO DETERMINE AND VERIFY THAT YOU ARE LEGALLY PERMITTED
UNDER THE LAWS OF YOUR RESIDENCE TO USE THE SERVICE.</span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">BY USING OUR SERVICES YOU
DECLARE THAT YOU AGREE TO ABIDE BY ALL APPLICABLE LOCAL AND INTERNATIONAL LAWS,
REGULATIONS AND RULES, INCLUDING ANY AND ALL LAWS, RULES AND REGULATION
PERTAINING TO GAMBLING, TABLE GAMES AND LOTTERIES.</span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:21.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:19.0pt;,serif;
color:#A41EB4">ABOUT US</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">The Games.</span></b><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">&nbsp;Baba Entertainment is
a social gaming platform, which offers players like you (</span><b><span lang="EN-US" style="font-size:12.0pt;color:#0E76A8;
border:none windowtext 1.0pt;padding:0cm">"Players"</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">) to play social games such as match 3, <a>poker and slot
machines </a></span>with other Players within the Service (collectively, the <b><span lang="EN-US" style="font-size:12.0pt;color:#0E76A8;
border:none windowtext 1.0pt;padding:0cm">"Games"</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">). Baba Entertainment also offers you live chat platform within
the Gamesthat allows you to communicate with other Players (</span><b><span lang="EN-US" style="font-size:12.0pt;color:#0E76A8;
border:none windowtext 1.0pt;padding:0cm">"Chat"</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">).</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Virtual Coins.&nbsp;</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;color:#040101">Games
may include virtual in-game currency including coins, cash or credits (</span><b><span lang="EN-US" style="font-size:12.0pt;color:#0E76A8;
border:none windowtext 1.0pt;padding:0cm">"Virtual coins"</span></b>).</p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">NO PURCHASE IS NECESSARY TO ENTER OR WIN A GAME. A PURCHASE OR
PAYMENT OF ANY KIND WILL NOT INCREASE YOUR CHANCES OF WINNING. VIRTUAL COINS
CAN BE USED ONLY WITHIN THE SERVICE AND MAY NEVER BE REDEEMED FOR MONEY, GOODS
OR OTHER ITEMS WITH MONETARY VALUE.</span></b></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Invitation.&nbsp;</span></b><span lang="EN-US" style="font-size:
12.0pt;,serif;color:#040101">You can invite
friends and contacts to join the Service through email, Whatsapp and social
networks websites, if permitted by these services. You assume full
responsibility for such invitations.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Chats.</span></b><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">&nbsp;As a Player you can
communicate with other Players using the Chat platform available through our
Service. You can chat with Players participating in a certain Gaming Table,
either privately or in a group chat. Likewise, other Players participating in a
certain Gaming Table may communicate with you.<br>
The Chat available through the Service is operated and controlled by Facebook,
all information and functionalities associated with a Chat, including its
members, permissions, properties, settings and Content, originate from Players
or Facebook, but not us. We do not control nor assume any responsibility for
the Chat system, its availability and the Content communicated through it.<br>
Please note that your use of the Chat platform, including the messages you send
and its content, is governed by Facebook’s&nbsp;</span><span lang="EN-US" style="color:black"><a href="https://www.facebook.com/terms">terms of use</a></span><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">&nbsp;and&nbsp;</span><span lang="EN-US" style="color:black"><a href="https://www.baba-entertainment.com/play-privacy-policy">Privacy Policy</a></span><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">&nbsp;, not these Terms.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:normal;background:white;
vertical-align:baseline"><span lang="EN" style="font-size:10.5pt;font-family:
Roboto;color:#212529">&nbsp;</span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:21.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:19.0pt;,serif;
color:#A41EB4">REGISTRATION TERMS/ACCOUNTS</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Account Creation.</span></b><span lang="EN-US" style="font-size:
12.0pt;,serif;color:#040101">&nbsp;The Service is
available only to registered users. Use of the Service requires registration of
an account through the Service. You agree never to use another user’s account
without permission. </span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Information you provide.</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;color:#040101"> When
you register to the Service, we will ask you to provide us the following details:
name, address, email, phone number, or other contact information&nbsp;(</span><b><span lang="EN-US" style="font-size:12.0pt;color:#0E76A8;
border:none windowtext 1.0pt;padding:0cm">"Registration Information"</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">). You must provide accurate and complete information. The name
you provide when registered for an account must match that on your
government-issued ID. Once registered, you may access the Service using your
account username and password. Make sure that you change your password
periodically. You must maintain the confidentiality of your account login
details.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">False information.&nbsp;</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;color:#040101">If
we believe that the Registration Information you provide is false, deceptive or
offensive, or if we believe that you violated these Terms, we reserve the right
to suspend or terminate your user account or your access to the Service.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Additional Information.&nbsp;</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;color:#040101">We
reserve the right to request additional information to verify your identity or
age, during the registration process, throughout your use of the Service or
when you submit support tickets related to your Service account.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Unauthorized Uses.&nbsp;</span></b><span lang="EN" style="font-size:
12.0pt;,serif;color:#040101">You are solely
responsible for the activity that occurs on your account, and you must keep
your password secure. You must notify us immediately of any breach of security
or unauthorized use of your account. Although Baba Entertainment is not liable
for any of your losses caused&nbsp; by any unauthorized use of your account, you
shall be liable for those losses of Baba Entertainment or others due to such
unauthorized use. You are allowed to register one (1) account, including any
inactive accounts you may have, on the Service. Should you attempt to open more
than one (1) account, all open accounts in your name may be suspended or
closed. You must notify us if you believe you have more than one account,
whether they are all active or not.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">User IDs and Passwords.</span></b> Access to certain areas of
the Service or the Content are limited by a user identifier (“User ID”) and
password, which is selected and/or supplied as part of the registration process
for an account with Baba Entertainment. By registering, you represent, warrant
and covenant that:</p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><span lang="EN" style="font-size:12.0pt;color:#040101">&nbsp;</span></p>

  <ol style="margin-top:0cm" start="1" type="1">
    <li class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
     115%"><span lang="EN" style="font-size:12.0pt;line-height:115%;">You are of lawful age in your jurisdiction to
     access Content, but in no case under 21 years of age;</span></li>
    <li class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
     115%"><span lang="EN" style="font-size:12.0pt;line-height:115%;">You are using your actual identity;</span></li>
    <li class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
     115%"><span lang="EN" style="font-size:12.0pt;line-height:115%;">You have provided only true, accurate, current,
     and complete information about yourself during the registration process;
     and</span></li>
    <li class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
     115%"><span lang="EN" style="font-size:12.0pt;line-height:115%;">You will maintain and promptly update the
     information that you provide to keep it true, accurate, current and
     complete.</span></li>
  </ol>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><span lang="EN" style="font-size:12.0pt;color:#040101">By logging onto the Service using any
password, you represent, warrant, and covenant that you are authorized to use
such password and to engage in the activities that you conduct thereunder. You
agree that you will be solely responsible for the maintenance and security of
your User ID and password. You also agree that you will be solely responsible
for any activities conducted on or through the Service that occur through your account
or with your User ID. Your User ID and password are unique to you, and you
agree not to disclose or share your User ID and password with any other person
or third-party. </span></p>

  <p class="MsoNormal" style="text-align:justify"><span lang="EN" style="font-size:
12.0pt;line-height:107%;,serif;color:#040101">In
addition, you agree that you are entirely </span><span lang="EN-US" style="font-size:12.0pt;line-height:107%;,serif">responsible
for any and all activities which occur under your account or with your User ID
whether or not you are the individual who undertakes such activities. This
includes any unauthorized access and/or use of your account should you fail to
sufficiently disconnect and/or log off the Service and/or a public computer
system. Accordingly, you absolve and release Baba Entertainment from any claim
of harm resulting from a third party undertaking the activities.</span></p>

  <p class="MsoNormal" style="text-align:justify"><span lang="EN-US" style="font-size:12.0pt;line-height:107%;,serif">We
reserve the right to deny or revoke access to the Service, or any part thereof,
at any time in our sole discretion, with or without cause. If you wish to
cancel a password or if you become aware of any loss, theft, or unauthorized
use of a password please notify us immediately. </span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:normal;background:white;
vertical-align:baseline"><a name="_Hlk160121464"><b><span lang="EN-US" style="font-size:12.0pt;color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Inactive Accounts.&nbsp;</span></b></a><span lang="EN-US" style="font-size:12.0pt;,serif;color:#040101">We
reserve </span><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">the </span><span lang="EN" style="font-size:12.0pt;color:#040101">right to close, deactivate, or terminate
your account if we deem, at our sole discretion, the account to be an Inactive
Account. An account is considered to be an Inactive Account, if no transaction
is recorded to an account for a period of twelve (12) consecutive</span>
months, we will remit the balance of the redeemed value of prizes in your account
to you or as otherwise may be required under applicable law.<span lang="EN-US" style="font-size:10.5pt;font-family:Roboto;color:#212529"><br>
<br>
</span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:21.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:19.0pt;,serif;
color:#A41EB4">YOUR PRIVACY</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;color:#040101">We respect your privacy. Our&nbsp;</span><span lang="EN-US" style="color:black"><a href="http://baba-entertainment.com/privacy-policy.html"><span style="font-size:12.0pt;,serif;color:#007BFF;
border:none windowtext 1.0pt;padding:0cm">Privacy Policy</span></a></span><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">, which is combines into these Terms, explains our privacy
practices. We encourage you to read it carefully.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:normal;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:10.5pt;font-family:
Roboto;color:#212529">&nbsp;</span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:21.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:19.0pt;,serif;
color:#A41EB4">FEE-BASED SERVICES</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Fee based Services.</span></b><span lang="EN-US" style="font-size:
12.0pt;,serif;color:#040101">&nbsp;Use of the
Service is free of charge. Except for Virtual Coins that you may purchase
within the Service, subject to fees at the rates and schemes determined and
posted next to them on the Service (</span><b><span lang="EN-US" style="font-size:12.0pt;color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">"Fee-Based Services"</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;color:#040101">).
Fee-Based Services can only be paid through PayPal, Facebook or one of the
other payment methods we establish from time to time. We reserve the right to
modify our Services.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Payment Administration Agents.</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;color:#040101">&nbsp;Baba
Entertainment in its sole discretion may appoint on or more payment
administration agents to accept payments, make payments, and facilitate the
payment process from or to players on Baba Entertainment’s behalf (“Payment
Administration Agents”). </span><span lang="EN" style="font-size:12.0pt;
,serif;color:#040101">Any Payment Administration
Agent appointed by us will possess the same rights, power and privileges as Baba
Entertainment under these Terms. Such appointed Payment Administration Agent
will be entitled to exercise and enforce their rights, powers and privileges as
our agent or in the Payment Administration Agent’s own name. Under no
circumstances will we be liable or responsible to any player for any loss,
damage or other liability resulting or arising from the Payment Administration
Agent’s negligence and/or acts which exceed the powers and authorities granted
to it by us.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Refund or Cancellation. </span></b><span lang="EN-US" style="font-size:12.0pt;,serif;color:#040101">Unless
otherwise mandated by law, all your payment obligations are non-cancelable and
all amounts paid in connection therewith are non-refundable by Baba Entertainment.
You are solely responsible for paying all applicable fees for the Products that
you purchased, whether or not you actually attend, enjoyed or otherwise
benefited from them.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Failing to Pay.</span></b><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">&nbsp;Failing to settle your
payments for Fee-Based Services will prevent you from continuing to use the
Service, without regard to any other remedies available to us under applicable
law.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:10.5pt;font-family:
Roboto;color:#212529"><br>
</span><a name="_Hlk160443399"><span lang="EN-US" style="font-size:19.0pt;
,serif;color:#A41EB4">VERIFICATION CHECKS</span></a></p>

  <p class="MsoNormal" style="margin-top:12.0pt;margin-right:0cm;margin-bottom:
0cm;margin-left:0cm;line-height:115%;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;line-height:115%;,serif;
color:#040101">You acknowledge and agree that Baba Entertainment shall be
entitled to engage in any identification, credit</span><span lang="EN-US" style="font-size:12.0pt;line-height:115%;,serif;
color:#040101">, or other verification checks as reasonably required or as
required by applicable laws and regulations or by any regulatory authorities. All
requests for prize redemption will remain pending until all necessary
verification checks have been completed to the sole satisfaction of us. From
time-to-time, we may request certain information from you in order to properly
complete a verification check, including but not limited to situations where
cumulative or single prize redemptions exceed a certain threshold. In the event
you fail to provide any documentation requested by us to help perform a
verification check within thirty (30) days of the request, we are under no
obligation to continue the verification process and may, in our sole
discretion, close, restrict, or suspend your account. Baba Entertainment may
also use third-party service providers to assist in the identification and
other verification checks using the information provided by you.</span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:21.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:19.0pt;,serif;
color:#A41EB4">RESPONSIBLE SOCIAL GAMEPLAY</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:115%;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;line-height:
115%;,serif;color:#040101">We actively support
responsible social gameplay and encourage you and any players to make use of a
variety of responsible social gameplay features. </span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:115%;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;line-height:
115%;,serif;color:#040101">You may, at any time,
request to take a break, self-exclude or permanently close your account. You
may also set a limit on your purchases, the amount play or the time you spend
logged into your account. </span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:115%;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;line-height:
115%;,serif;color:#040101">We are committed to
providing excellent customer service. As part of that pledge we are committed
to supporting responsible social gameplay. We refer you to our <a href="https://www.baba-entertainment.com/play-responsible-play">Responsible Social Gameplay Policy</a>for full details. </span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:115%;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;line-height:
115%;,serif;color:#040101">Although we will use
all reasonable efforts to enforce its responsible social gameplay policies, we
do not accept any responsibility or liability if you nevertheless continue
gameplay and/or seek to use the Services or App with the intention of
deliberately avoiding the relevant measures in place and/or we are unable to
enforce its measures/policies for reasons outside of our reasonable control.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:normal;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;color:#040101">&nbsp;</span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:21.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:19.0pt;,serif;
color:#A41EB4">COMMERCIAL CONTENT</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Ads.</span></b><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">&nbsp;We may incorporate
advertisements or commercial information in the Service. We do not control the
ads and cannot guarantee that they are reliable or accurate. Including them in
the Service does not mean that we recommend or endorse the goods or services
that they offer. If you choose to purchase them, you do it on your own accord
and we bear no responsibility for your decision or its consequences.</span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:21.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:19.0pt;,serif;
color:#A41EB4">CONTENT</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
115%"><span lang="EN-US" style="font-size:12.0pt;line-height:115%;">In addition to the general restrictions on the use of
Service below, the follow restrictions and conditions apply specifically to
your Content on the Service.</span></p>

  <ol style="margin-top:0cm" start="1" type="A">
    <li class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
     115%"><span lang="EN-US" style="font-size:12.0pt;line-height:115%;
     ,serif">You agree to not engage in the use,
     copying, or distribution of any of the Content other than expressly
     permitted herein, including any use, copying, or distribution of materials
     of third parties obtained through Baba Entertainment for any commercial
     purposes.</span></li>
    <li class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
     115%"><span lang="EN-US" style="font-size:12.0pt;line-height:115%;
     ,serif">You agree not to circumvent, disable
     or otherwise interfere with security-related features of Baba
     Entertainment or features that prevent or restrict use or copying of any
     Content or enforce limitations on use of Baba Entertainment or the Content
     therein.</span></li>
    <li class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
     115%"><span lang="EN-US" style="font-size:12.0pt;line-height:115%;
     ,serif">You understand that when using the
     Service, you may be exposed to content from a variety of sources, and that
     Baba Entertainment is not responsible for the accuracy, usefulness,
     safety, or intellectual property rights of or relating to such third party
     content.</span></li>
    <li class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
     115%"><span lang="EN-US" style="font-size:12.0pt;line-height:115%;
     ,serif">You further understand and
     acknowledge that you may be exposed to third party content that is
     inaccurate, offensive, indecent, or objectionable, and you agree to waive,
     and hereby do waive, any legal or equitable rights or remedies you have or
     may have against us with respect thereto, and agree to indemnify and hold Baba
     Entertainment, its owners/operators, affiliates, and/or licensors,
     harmless to the fullest extent allowed by law regarding all matters
     arising from your use of the site.</span></li>
  </ol>

  <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;line-height:115%"><span lang="EN-US" style="font-size:12.0pt;line-height:115%;,serif">&nbsp;</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Links.</span></b><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">&nbsp;The Service may
include links to other online resources that third parties provide. We do not
operate or monitor these online resources or their content. We assume no responsibility
or liability for such third party content or their availability and we bear no
responsibility for your decision to open such links and the consequences of
doing so.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;color:#040101">&nbsp;</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:normal;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;color:#040101">We have no control over, and assume no
responsibility for the content, privacy policies, or practices of any third party
websites. In addition, we will not and cannot censor or edit the content of any
third party website. By using the Service, you expressly relieve Baba
Entertainment from any and all liability arising from your use of any third party
website.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:115%"><span lang="EN" style="font-size:12.0pt;line-height:115%;,serif">&nbsp;</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:115%"><span lang="EN" style="font-size:12.0pt;line-height:115%;,serif">All
Third Party Websites are bound by their own Terms of Service outlined by that
Party.</span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:21.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:19.0pt;,serif;
color:#A41EB4">USE OF THE SERVICE</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;color:#040101">We hereby grant you permission to access
and use the Service as set forth herein. You are solely responsible for the
content you make available through the service and for the consequences
associated with doing so.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Prohibited use.&nbsp;</span></b><span lang="EN-US" style="font-size:
12.0pt;,serif;color:#040101">When using the
Service, you must refrain from: </span></p>

  <ul style="margin-top:0cm" type="disc">
    <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
     background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">Using the Service
     if you are under 21 years of age or under the legal age of majority in
     your jurisdiction;</span></li>
    <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
     background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">Using or
     attempting to use the Service from an excluded jurisdiction;</span></li>
    <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
     background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">Breaching
     these Terms or any other applicable rules and instructions that we may
     convey with respect to the Service;</span></li>
    <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
     background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">Engaging in
     any activity that constitutes or encourages conduct that would constitute
     a criminal offense, give rise to civil liability or otherwise violate any
     applicable law, including laws governing privacy, defamation, spam and
     copyright;</span></li>
    <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
     background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">Interfering
     with, burdening or disrupting the functionality of the Service;</span></li>
    <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
     background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">Breaching the
     security of the Service or publicly identifying any security
     vulnerabilities in it;</span></li>
    <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
     background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">Circumventing
     or manipulating the operation or functionality of the Service, or
     attempting to enable features or functionalities that are otherwise
     disabled, inaccessible or undocumented in the Service;</span></li>
    <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
     background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">Sending
     automated or machine generated queries;</span></li>
    <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
     background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">Using robots,
     crawlers and similar applications to collect and compile content from the
     Service or send data to the Service including for the purposes of
     competing with the Service, or in such ways that may impair or disrupt the
     Service's functionality;</span></li>
    <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
     background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">Displaying or
     embedding content from the Service, including by any software, feature,
     gadget or communication protocol, which alters the content or its design;</span></li>
    <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
     background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">Impersonating
     any person or entity, or making any false statement pertaining to your
     identity or affiliation with any person or entity;</span></li>
    <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
     background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">Collecting,
     harvesting, obtaining or processing personal information regarding the
     Service’s users, without their prior explicit consent;</span></li>
    <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
     background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">Abusing,
     harassing, threatening or intimidating other users of the Service; Linking
     to the Service from web pages or applications that contain pornographic
     content or content that encourages racism or wrongful discrimination or
     any other unlawful or prohibited content;</span></li>
    <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
     background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">Using the
     Services for any commercial use without the prior written authorization of
     us. Prohibited commercial uses do not include any use that Baba
     Entertainment expressly authorizes in writing. Prohibited commercial uses
     include any of the following actions taken without Baba Entertainment’s
     express approval:</span></li>
    <ul style="margin-top:0cm" type="circle">
      <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
      background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">Sale of
      access to Services or its related services on another website;</span></li>
      <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
      background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">Use of the
      Services or its related services for the primary purpose of gaming
      advertising or subscription revenue;</span></li>
      <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
      background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">The sale of
      advertising, on the Baba Entertainment website or any Thid Party Website;</span></li>
      <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
      background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">Any use of
      the Services or its related services that we find, in our sole
      discretion, to uses Service Content with the effect of competing with or
      displacing the market for the Service or Service Content.</span></li>
    </ul>
    <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
     background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">Sending,
     uploading to or making available on the Service Content which may be
     considered as -</span></li>
    <ul style="margin-top:0cm" type="circle">
      <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
      background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">Infringing
      or violating intellectual property rights of other parties, including
      copyrights, patents, trade secrets and trademarks;</span></li>
      <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
      background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">Identifying
      minors, their personal details or their address and ways to contact them;</span></li>
      <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
      background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">Software
      viruses, Trojan Horses, worms, vandals, spyware, ransomware and any other
      malicious applications;</span></li>
      <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
      background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">Constituting
      a violation of a person's right to privacy or right of publicity;</span></li>
      <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
      background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">Threatening,
      abusive, harassing, defamatory, libellous, vulgar, violent, obscene or
      racially, ethnically or otherwise objectionable;</span></li>
      <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
      background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">Unsolicited
      commercial communications ('spam'), chain letters, or pyramid schemes.</span></li>
      <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
      background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">Otherwise
      prohibited by any applicable law</span></li>
    </ul>
  </ul>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">We encourage respectful behavior.&nbsp;</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">If you find any content on the Service that violates these
Terms, please let us know by contacting us at:&nbsp;</span><span lang="EN-US" style="color:black"><a href="mailto:info@babaent.net"><span style="font-size:
12.0pt;,serif;color:#007BFF;border:none windowtext 1.0pt;
padding:0cm">info@babaent.net</span></a></span><span lang="EN-US" style="font-size:12.0pt;,serif;color:#040101">. We
will review every request and take action as necessary.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:normal;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:10.5pt;font-family:
Roboto;color:#212529">&nbsp;</span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:21.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:19.0pt;,serif;
color:#A41EB4">INTELLECTUAL PROPERTY</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Our intellectual property.&nbsp;</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;color:#040101">All
rights, title and interest in and to the Service, including without limitation,
patents, copyrights, trademarks, trade names, Service marks, trade secrets and
other intellectual property rights and any goodwill associated therewith, but
excluding any Content that originates from Users, are the exclusive property of
Baba Entertainment and its licensors. This including the Service’s design,
graphics, computer code, “look and feel” and the Company’s domain name.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Restrictions.</span></b><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">&nbsp;You may not copy,
distribute, display or perform publicly, make available to the public or
communicate to the public, sublicense, decompile, disassemble, reduce to human
readable form, execute publicly, make available to the public, adapt, make
commercial use of, process, compile, translate, sell, lend, rent, reverse
engineer, combine with other software, modify or create derivative works of the
Service or the Service or any part thereof, in any way or by any means.<br>
You may not use any name, mark, logo or domain name that is confusingly similar
to our marks, logos and Internet domain names. You must refrain from any action
or omission that may dilute, or damage our goodwill.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Notice of Enforcement.</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;color:#040101">&nbsp;We
vigorously enforce intellectual property rights to the fullest extent of the
law. Accordingly, we may prosecute any unauthorized use or reproduction of any
of the Content or databases and any other information contained therein that
violate the protection afforded by the United States and international
copyright law and trademark law, and/or other state, federal, and international
laws and regulations, including laws pertaining to contracts, and privacy and
publicity. </span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Notice and Procedure for Making Claims under the Digital
Millennium Copyright Act.</span></b><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">&nbsp;The Digital Millennium
Copyright Act (DMCA) provides resources to copyright owners who believe that
their rights under the United States Copyright Act have been infringed by acts
of third parties over the Internet. If an individual believes that their
copyrighted work has been copied without their authorization and is available
within the Service in a way that may constitute copyright infringement, he/she
may provide notice of their claim to our designated agent listed below.&nbsp; For
notice to be effective, it must include the following information:</span></p>

  <ul style="margin-top:0cm" type="disc">
    <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
     background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">A physical or
     electronic signature of a person authorized to act on behalf of the owner
     of an exclusive right that is allegedly infringed;</span></li>
    <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
     background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">A description
     of the copyrighted work that You claim has been infringed; </span></li>
    <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
     background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">A description
     of where the allegedly infringing material is located within the Service; </span></li>
    <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
     background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">Information
     reasonably sufficient to permit us to contact the complaining party, such
     as address, telephone number, and, if available, an e-mail address at
     which the complaining party may be contract;</span></li>
    <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
     background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">A statement
     that you have a good-faith belief that the disputed use is not authorized
     by the copyright owner, its agent, or the law; and</span></li>
    <li class="MsoNormal" style="color:#040101;margin-bottom:0cm;line-height:18.0pt;
     background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif">A statement
     that the information in the notification is accurate and under penalty of
     perjury, that the complaining party is authorized to act on behalf of the
     owner of an exclusive right that is allegedly infringed.</span></li>
  </ul>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;color:#040101">Baba Entertainment’s
Designated Agent is:</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;color:#040101">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span>Name: Alon Shimony</span></span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;color:#040101;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Address: 23 Begin Road, Tel Aviv, Israel</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;color:#040101;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; E-mail: privacy@baba-ent.com</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;color:#040101">&nbsp;</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;color:#040101">Our designated agent should be contacted
only if an individual believes that their work has been used or copied in a way
that constitutes copyright infringement and such infringement is occurring
within the Service. All other inquiries to our designated agent will not be
answered.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:normal;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:10.5pt;font-family:
Roboto;color:#212529">&nbsp;</span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:21.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:19.0pt;,serif;
color:#A41EB4">GAMES</span><span lang="EN-US" style="font-size:10.5pt;font-family:
Roboto;color:#212529"><br>
</span><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">All Games available to play both on the classic and sweeps
version of the Service possess their own rules and odds of winning. The
individual rules and odds for each Game will be available to players once the
Game has been launched. It is the player’s responsibility to read the rules
ahead of playing and familiarize themselves with all terms of play prior to
playing each Game.</span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:21.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">We reserve the right to declare any one play, spin, or other
form of participation in a Game void, either partially or in full, if there is
an error, mistake, misprint, or technical error.</span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:21.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">In the event of a difference or discrepancy between the result
showing on a player’s screen and the result showing on the Service software
server, the result showing on the Service software server shall be the
governing result. </span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:21.0pt;background:white;vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;color:#0E76A8;
border:none windowtext 1.0pt;padding:0cm">Sweeps Play and Promotions.</span></b><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">&nbsp;Sweeps Play is
governed by the Baba Entertainment Sweepstakes Rules and these Terms of
Service. All other promotions we may from time to time are governed by these
Terms. In the event of a conflict between these Terms and Baba Entertainment’s
Official Sweepstakes Rules, or promotion-specific terms, the Baba Entertainment
Official Sweepstakes Rules, or promotion-specific terms, will prevail.</span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:21.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">To access the Sweeps Game, a player must enter </span><span lang="EN-US" style="color:black"><a href="http://babacasino.com"><span style="font-size:12.0pt;,serif">http://babacasino.com</span></a></span><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101"> and login/create-account. For more information regarding Baba
Entertainment Sweeps Play, see the Baba Entertainment Official Sweepstakes
Rules.</span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:21.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:19.0pt;,serif;
color:#A41EB4">VIRTUAL COINS</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:115%;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;line-height:
115%;,serif;color:#040101">We may permit Virtual
Coins to be used for accessing and playing Content and/or Games. 2 types of
Virtual Coins will be available to players to play Games on both the classic
and sweepstakes platforms: Baba Coins (“BC”), Sweeps Coins (“SC”).</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:115%;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;line-height:
115%;,serif;color:#040101">&nbsp;</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:115%;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;line-height:
115%;,serif;color:#040101">BC are used to </span><span lang="EN" style="font-size:12.0pt;line-height:115%;,serif;
color:#040101">play games in standard social casino gameplay on the Baba Casino. BC have no real money value, are not redeemable for any
government-issued currency, and are only intended to enhance gameplay for players
playing games in standard social casino gameplay on the Baba Casino. BC may
be obtained for free in various ways such as a daily bonus, a free allotment
every 4 hours, a daily wheel spin, by winning spins in standard social casino gameplay,
or other methods we may from time to time establish</span>. BC may also be purchased from Baba Entertainment for valid
government-issued currency. By purchasing BC, you agree (1) such acquisition is
valid and legal in your jurisdiction, and (2) once purchased, BC are not
redeemable for any government-issued currency that is, such BC should be
treated as a “good” purchased as-is, with no returnable or redeemable value
except within the entertaining Content provided by Baba Entertainment, or as
otherwise expressly indicated by us.</p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:115%;background:white;
vertical-align:baseline"><span lang="EN" style="font-size:12.0pt;line-height:
115%;,serif;color:#040101">&nbsp;</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:115%;background:white;
vertical-align:baseline"><span lang="EN" style="font-size:12.0pt;line-height:
115%;,serif;color:#040101">SCs</span> are only available for gameplay on the sweeps Baba Casino when players elect to play games in sweeps play. SC are not available for purchase like BC are. SC may
be obtained in a number of ways such as a daily allowance provided upon
login+, as a gift to purchasing BC, or by submitting a Request Card. See the Baba Entertainment Official Sweepstakes Rules for more
information on how to collect, use, and redeem SC. NO PURCHASE IS NECESSARY TO
OBTAIN SC.</p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:115%;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;line-height:
115%;,serif;color:#040101">&nbsp;</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:115%;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;line-height:
115%;,serif;color:#040101">You may not transfer
Virtual Coins between accounts, or from your account to that of another.
Further, you may not receive Virtual Coins from other users into your account.
Any attempt to circumvent these prohibitions may be grounds for termination of
your account, without prejudice to any other rights or remedies available to
us.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:115%;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;line-height:
115%;,serif;color:#040101">&nbsp;</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:115%;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;line-height:
115%;,serif;color:black">You agree that Baba
Entertainment has the absolute right to manage, regulate, control, modify
and/or eliminate such Virtual Currencies as they see fit in their sole
discretion, in any general or specific case, and that they will have no
liability to you based on their exercise of such rights.&nbsp; You agree that you
have no right or title in or to any such Virtual Currencies appearing or
originating on Baba Entertainment.</span><span lang="EN-US" style="font-size:
10.5pt;line-height:115%;font-family:Roboto;color:#212529"><br>
<br>
</span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:21.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:19.0pt;,serif;
color:#A41EB4">TERMINATION</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:normal;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:10.5pt;font-family:
Roboto;color:#212529">&nbsp;</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Terminating Your Account.&nbsp;</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;color:#040101">You
may, at any time, request to terminate your account by contacting us at&nbsp;</span><span lang="EN-US" style="color:black"><a href="mailto:info@babaent.net"><span style="font-size:12.0pt;,serif;color:#007BFF;
border:none windowtext 1.0pt;padding:0cm">info@babaent.net</span></a></span><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">. Please note that merely uninstalling the App won't delete your
account and its details, including all the information associated with it,
which would still remain on our systems.</span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">We may temporarily or
permanently limit, block your access to or terminate your user account, if we
determine that you breached these Terms.</span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">Upon termination of these
Terms or your account, for any reason, your right to use the Service is
terminated and you must immediately cease using the Service; and we will not be
liable to you for termination of access to the Service.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Operation of the Service.&nbsp;</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;color:#040101">We
may at any time discontinue or terminate the operation of the App or the
Service, or any part thereof, temporarily or permanently, for all users, or for
certain users, without any liability to you.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:normal;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:10.5pt;font-family:
Roboto;color:#212529">&nbsp;</span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:21.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:19.0pt;,serif;
color:#A41EB4">CHANGES</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Changing the Service.&nbsp;</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;color:#040101">We
may, at any time and without prior notice, change the layout, design, scope,
features or availability of the App and the Service.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Changing these Terms.&nbsp;</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;color:#040101">We
may revise these Terms, in whole or in part, at any time by putting you on
notice of the amended Terms. Your continued use of the Service after the
effective date of the amended Terms constitutes your consent to the amended
Terms.</span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:21.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:19.0pt;,serif;
color:#A41EB4">TERRITORIAL AVAILABILITY</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;color:#040101">The Services, or any feature thereof
(including any and all Games, promotions, challenges, and Content), may not be
available in all territories and jurisdictions and we make no representations
that the Services are or shall remain available for use in any particular
territories and jurisdictions.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;color:#040101">You acknowledge and agree that we may
(at our sole discretion) change, restrict or prohibit the availability of all
or a portion of the Services in certain territories and jurisdictions at any
time, and you will have no claims against use resulting therefrom. </span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:21.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:19.0pt;,serif;
color:#A41EB4">DISCLAIMER OF WARRANTY</span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">THE SERVICE IS PROVIDED ON
AN “AS IS” AND “AS AVAILABLE” BASIS, WITH ALL FAULTS, EITHER EXPRESSED OR
IMPLIED. WE AND OUR OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES,
SUB-CONTRACTORS, AGENTS AND AFFILIATES (THE “STAFF”) DISCLAIM ALL WARRANTIES
AND REPRESENTATIONS, EITHER EXPRESS OR IMPLIED, WITH RESPECT TO THE SERVICE,
ITS CONTENT, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES AND CONDITIONS
EXPRESSED OR IMPLIED, INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
PARTICULAR PURPOSE, QUALITY, NON-INFRINGEMENT, TITLE, COMPATIBILITY, PERFORMANCE,
SECURITY, AVAILABILITY, ACCURACY, RELIABILITY,COMPLETENESS, CURRENCY,
SUITABILITY, ENHANCED GOODWILL, OR EXPECTED BENEFITS OF THIS SERVICE, THE
CONTENT, OR ANY PRODUCTS, SERVICES OR RESULTS OBTAINED ON OR THROUGH BABA
ENTERTAINMENT.</span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">WE DO NOT WARRANT THAT (1)
THE SERVICE WILL BE UNINTERRUPTED, ERROR FREE OR WILL MEET YOUR REQUIREMENTS OR
EXPECTATIONS IN ANY WAY; (2) THE SERVICE WILL ALWAYS BE AVAILABLE OR FREE FROM DEFECTS,
MALWARES, COMPUTER VIRUSES OR OTHER HARMFUL COMPONENTS; (3) THE QUALITY OF THE
SERVICE, THE GAMES, CHATS OR OTHER CONTENT AVAILABLE THROUGH OR IN THE SERVICE,
WILL MEET YOUR EXPECTATIONS; (4) THE RESULTS OF THE USE OF THE SERVICE WILL BE
SATISFACTORY AND WILL FIT YOUR EXPECTATIONS OR REQUIREMENTS.</span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">NO REPRESENTATION OR OTHER
AFFIRMATION OF FACT, INCLUDING STATEMENTS REGARDING CAPACITY, SUITABILITY FOR
USE OR PERFORMANCE OF THE SERVICE, OR THE CONTENT PRESENTED ON OR THROUGH THE
SERVICE, WHETHER OR NOT MADE BY ANY OF OUR STAFF, WHICH IS NOT EXPRESSLY
CONTAINED IN THESE TERMS, SHALL BE DEEMED TO BE A WARRANTY BY THE INVOLVED
PERSONS FOR ANY PURPOSE, OR GIVE RISE TO ANY LIABILITY OF OUR STAFF WHATSOEVER.</span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">YOU AGREE AND ACKNOWLEDGE
THAT THE USE OF THE SERVICE IS ENTIRELY, OR AT THE MAXIMUM PERMITTED BY THE
APPLICABLE LAW, AT YOUR OWN SOLE RISK. </span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:21.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:19.0pt;,serif;
color:#A41EB4">LIMITATION OF LIABILITY</span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">TO THE MAXIMUM EXTENT
PERMITTED BY APPLICABLE LAW, WE AND OUR STAFF, SHALL NOT BE LIABLE, FOR ANY INDIRECT,
SPECIAL, PUNITIVE, EXEMPLARY, STATUTORY, INCIDENTAL OR CONSEQUENTIAL DAMAGE
INCLUDING BODILY INJURY OR OTHER DAMAGE CAUSED BY USING THE SERVICE, OR ANY
SIMILAR DAMAGE OR LOSS (INCLUDING LOSS OF PROFIT, GOODWILL, LOSS OF DATA OR
OTHER INTANGIBLE ITEMS), COSTS, EXPENSES AND PAYMENTS, EITHER IN TORT,
CONTRACT, OR IN ANY OTHER FORM OR THEORY OF LIABILITY, ARISING FROM, OR IN
CONNECTION WITH THE CONTENT, THE USE OF, OR THE INABILITY TO USE THE SERVICE OR
ITS FEATURES, OR FROM ANY FAILURE, ERROR, OR BREAKDOWN IN THE FUNCTION OF THE
SERVICE, OR FROM ANY FAULT, OR ERROR MADE BY OUR STAFF, OR FROM YOUR RELIANCE
ON CONTENT AVAILABLE ON OR THROUGH THE SERVICE, OR FROM ANY COMMUNICATION
THROUGH THE SERVICE, OR WITH OTHER USERS ON THE SERVICE, OR FROM ANY DENIAL OR
CANCELATION OF YOUR USER ACCOUNT, OR FROM RETENTION, DELETION, DISCLOSURE AND
ANY OTHER USE OR LOSS OF YOUR CONTENT ON THE SERVICE, OR PRODUCTS OR SERVICES
OBTAINED ON OR THROUGH THE SERVICE, EVEN IF THERE IS NEGLIGENCE BY US OR BY
STAFF, OR AN STAFF HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR ANY
OF THE ABOVE</span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">IN ANY EVENT, TO THE MAXIMUM
EXTENT PERMITTED BY APPLICABLE LAW, OUR TOTAL, MAXIMUM AND AGGREGATE LIABILITY
TO YOU FOR DIRECT DAMAGES SHALL BE LIMITED TO HALF THE FEES YOU PAID US (IF
ANY) IN THE TWELVE MONTHS PRECEDING THE EVENT PURPORTEDLY GIVING RISE TO THE
CLAIM.</span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">NOTWITHSTANDING ANYTHING
HEREIN TO THE CONTRARY, WE WILL BE FULLY RELEASED FROM OUR OBLIGATIONS AND
LIABILITY TO YOU IF YOU HAVE BREACHED THESE TERMS, ANY OTHER TERMS, RULES OR
REGULATIONS APPLICABLE TO THE SERVICE, OR IF THROUGH YOUR USE OF THE SERVICE,
YOU INFRINGED OR VIOLATED ANY OTHER PERSON’S RIGHTS.</span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:21.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:19.0pt;,serif;
color:#A41EB4">INDEMNIFICATION</span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">To the maximum extent
permitted by law, you will indemnify and hold harmless at your own expense, us,
our Staff and anyone acting on our behalf, from and against any damages, costs
and expenses, resulting from any claim, allegation or demand (including
reasonable attorneys’ fees and litigation expenses), connected with or arising
from:</span></p>

  <p class="MsoListParagraphCxSpFirst" style="margin-bottom:9.0pt;text-indent:-18.0pt;
line-height:18.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;font-family:Symbol;color:#040101">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span dir="LTR"></span><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">Your use of the Service and any of the Content, or any products
or services obtained on through the Service;</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-bottom:9.0pt;text-indent:
-18.0pt;line-height:18.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;font-family:Symbol;color:#040101">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span dir="LTR"></span><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">Any arrangements you make based on the content obtained on or
through the Service;</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-bottom:9.0pt;text-indent:
-18.0pt;line-height:18.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;font-family:Symbol;color:#040101">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span dir="LTR"></span><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">Any breach by you of the Terms;</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-bottom:9.0pt;text-indent:
-18.0pt;line-height:18.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;font-family:Symbol;color:#040101">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span dir="LTR"></span><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">Any activity related to your account (including infringement of
third parties’ worldwide intellectual property rights or negligent or wrongful
conduct) by you or any other person accessing the Service using your account;</span></p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-bottom:9.0pt;text-indent:
-18.0pt;line-height:18.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;font-family:Symbol;color:#040101">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span dir="LTR"></span><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">Any circumvention or attempted circumvention of the Terms or the
Service; or</span></p>

  <p class="MsoListParagraphCxSpLast" style="margin-bottom:9.0pt;text-indent:-18.0pt;
line-height:18.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;font-family:Symbol;color:#040101">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span dir="LTR"></span><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">Any fraud committed with regard to us.</span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">We reserve the right, at
your expense, to assume institute civil or criminal claims against you, report
you to the relevant regulatory authorities, and to assume the exclusive defense
and control of any matter for which you are required to indemnify us, and you
agree to cooperate with our defense of these claims. We will use reasonable
efforts to notify you of any such claim, action, or proceeding upon becoming
aware of it. You agree that the provisions in this section will survive any
termination of your account or the Service. </span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:21.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:19.0pt;,serif;
color:#A41EB4">LICENSE</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
115%"><span lang="EN" style="font-size:12.0pt;line-height:115%;,serif">You
agree by accessing, browsing or otherwise using the Service, Baba Entertainment
and the Content are and remain the sole property of Baba Entertainment or
third-party providers, as applicable. Except as expressly permitted by us in
writing, you agree that your license to access, browse and/or use this content
is limited solely to a personal, revocable, nonexclusive, non-transferable
license to (i) access and view Baba Entertainment and the content, and (ii)
upload and store any Content for your non-commercial use (with the exception of
any fiscal or monetary reward provided by us) and not for resale or
distribution to anyone else. You also agree that, with respect to any copy of
the Content that you are permitted to upload, download, store and/or
record/print, you will reproduce and include all copyright and other
proprietary notices included in such Content.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
115%"><span lang="EN" style="font-size:12.0pt;line-height:115%;,serif">&nbsp;</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;text-align:justify;line-height:
115%"><span lang="EN" style="font-size:12.0pt;line-height:115%;,serif">Unless
otherwise provided in writing by us, no license provided herein authorizes you
to copy, reproduce, modify, adapt, translate, distribute, transmit, download,
upload, post, sell, rent, license, transfer, publicly perform, publicly
display, mirror, frame, scrape, extract, wrap, create derivative works of,
reverse engineer, use in a manner prohibited by applicable laws or regulations,
decompile or disassemble any aspect of the Service or any of the content, in
whole or in part, in any form or by any means, whether manual or automatic.</span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:21.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:19.0pt;,serif;
color:#A41EB4">APPLICATION MARKETPLACE</span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">Your use of the Service may
be subject to additional third party terms and conditions that govern that
application marketplace from which you downloaded the App, such as Apple,
Google Play or Amazon App-store for Android. Such third parties are not
responsible for providing maintenance and support services with respect to the
App.</span></p>

  <p class="MsoNormal" style="text-align:justify"><b><span lang="EN-US" style="font-size:12.0pt;line-height:107%;
color:#0E76A8;border:none windowtext 1.0pt;padding:0cm">Apple Disclaimers.&nbsp;</span></b><span lang="EN-US" style="font-size:12.0pt;line-height:107%;,serif">You
acknowledge and agree that these Terms are solely between you and Baba
Entertainment only, and not with Apple, Inc. (“Apple”).&nbsp; You further
acknowledge and agree that:</span></p>

  <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:72.0pt;text-align:justify;text-indent:-18.0pt;line-height:115%"><span lang="EN-US" style="font-size:12.0pt;line-height:115%;font-family:&quot;Courier New&quot;">o<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp; </span></span><span dir="LTR"></span><span lang="EN-US" style="font-size:12.0pt;line-height:115%;,serif">Apple
and its subsidiaries are third-party beneficiaries under these Terms and that,
upon your acceptance of these Terms, Apple will have the right (and will be
deemed to have accepted the right) to enforce the terms and conditions of these
Terms against you as a third-party beneficiary thereof.</span></p>

  <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:72.0pt;text-align:justify;text-indent:-18.0pt;line-height:115%"><span lang="EN-US" style="font-size:12.0pt;line-height:115%;font-family:&quot;Courier New&quot;">o<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp; </span></span><span dir="LTR"></span><span lang="EN-US" style="font-size:12.0pt;line-height:115%;,serif">Apple
has no responsibility for the iOS App or any included content.</span></p>

  <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:72.0pt;text-align:justify;text-indent:-18.0pt;line-height:115%"><span lang="EN-US" style="font-size:12.0pt;line-height:115%;font-family:&quot;Courier New&quot;">o<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp; </span></span><span dir="LTR"></span><span lang="EN-US" style="font-size:12.0pt;line-height:115%;,serif">In
the event of any failure of the iOS App to conform to any applicable warranty,
you may notify Apple, and Apple will refund the purchase price for the iOS App
to you (if any).&nbsp; To the maximum extent permitted by applicable law, Apple will
have no other warranty obligation whatsoever with respect to the iOS App, and
any other claims, losses, liabilities, damages, costs or expenses attributable
to any failure to conform to any warranty.</span></p>

  <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:72.0pt;text-align:justify;text-indent:-18.0pt;line-height:115%"><span lang="EN-US" style="font-size:12.0pt;line-height:115%;font-family:&quot;Courier New&quot;">o<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp; </span></span><span dir="LTR"></span><span lang="EN-US" style="font-size:12.0pt;line-height:115%;,serif">Apple
is not responsible for addressing any of your claims or those of any
third-party relating to the iOS App or your possession and/or use of the iOS App,
including, without limitation: (i) maintenance and support; (ii) product
liability claims; (iii) any claim that the iOS App failed to conform to any
applicable legal or regulatory requirement; and (iv) claims arising under
consumer protection or similar legislation.</span></p>

  <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:72.0pt;text-align:justify;text-indent:-18.0pt;line-height:115%"><span lang="EN-US" style="font-size:12.0pt;line-height:115%;font-family:&quot;Courier New&quot;">o<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp; </span></span><span dir="LTR"></span><span lang="EN-US" style="font-size:12.0pt;line-height:115%;,serif">In
the event of any third-party claim that the iOS App or your possession and use
of the iOS App infringes that third-party’s intellectual property rights, Apple
will not have any responsibility for the investigation, defense, settlement,
and discharge of such claim.</span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Google Disclaimers.&nbsp;</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;color:#040101">You acknowledge
and agree that these Terms are solely between you and Baba Entertainment only,
and not with Google, Inc. (“Google”).&nbsp; You further acknowledge and agree that:</span></p>

  <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:9.0pt;
margin-left:72.0pt;text-indent:-18.0pt;line-height:18.0pt;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;font-family:
&quot;Courier New&quot;;color:#040101">o<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">Google and its subsidiaries
are third-party beneficiaries under these Terms and that, upon your acceptance
of the Terms, Google will have the right (and will be deemed to have accepted
the right) to enforce the terms and conditions of the Terms against you as a
third-party beneficiary thereof.</span></p>

  <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:9.0pt;
margin-left:72.0pt;text-indent:-18.0pt;line-height:18.0pt;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;font-family:
&quot;Courier New&quot;;color:#040101">o<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">Google has no responsibility
for the App or any included content.</span></p>

  <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:9.0pt;
margin-left:72.0pt;text-indent:-18.0pt;line-height:18.0pt;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;font-family:
&quot;Courier New&quot;;color:#040101">o<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">In the event of any failure
of the Android App to conform to any applicable warranty, you may notify
Google, and Google will refund the purchase price for the Android App to you
(if any).&nbsp; To the maximum extent permitted by applicable law, Google will have
no other warranty obligation whatsoever with respect to the Android App, and
any other claims, losses, liabilities, damages, costs or expenses attributable
to any failure to conform to any warranty.</span></p>

  <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:9.0pt;
margin-left:72.0pt;text-indent:-18.0pt;line-height:18.0pt;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;font-family:
&quot;Courier New&quot;;color:#040101">o<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">Google is not responsible
for addressing any of your claims or those of any third-party relating to the
Android App or your possession and/or use of the Android App, including,
without limitation: (i) maintenance and support; (ii) product liability claims;
(iii) any claim that the App failed to conform to any applicable legal or
regulatory requirement; and (iv) claims arising under consumer protection or
similar legislation.</span></p>

  <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:9.0pt;
margin-left:72.0pt;text-indent:-18.0pt;line-height:18.0pt;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;font-family:
&quot;Courier New&quot;;color:#040101">o<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">In the event of any
third-party claim that the Android App or your possession and use of the
Android App infringes that third-party’s intellectual property rights, Google
will not have any responsibility for the investigation, defense, settlement,
and discharge of such claim.</span></p>

  <p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:9.0pt;
margin-left:72.0pt;text-indent:-18.0pt;line-height:18.0pt;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;font-family:
&quot;Courier New&quot;;color:#040101">o<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;
</span></span><span dir="LTR"></span><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">Your use of the Android App
must comply with Google’s then-current Android Market Terms of Service.</span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:21.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:19.0pt;,serif;
color:#A41EB4">DISPUTE RESOLUTION AND AGREEMENT TO ARBITRATE ON AN INDIVIDUAL
BASIS </span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">PLEASE READ THIS SECTION
BECAUSE IT MAY REQUIRE YOU AND US TO ARBITRATE CERTAIN DISPUTES AND CLAIMS ON
AN INDIVIDUAL BASIS AND LIMITS THE MANNER IN WHICH YOU AND THE COMPANY CAN
SEEKS RELIEF FROM EACH OTHER.</span></b></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">If you reside in or access
the Services at any time while located in the United States, this section&nbsp;
(Dispute Resolution and Agreement to Arbitrate on an Individual Basis) shall be
construed under and be subject to the Federal Arbitration Act, notwithstanding
any other choice of law set out in these Terms. By agreeing to these Terms, and
to the extent permitted by applicable law, you and Baba Entertainment agree
that any and all past, present and future disputes, claims or causes of action
between you and us arising out of or relating to these Terms, the Services,
App, and Games, the formation of these Terms or any other dispute between you
and us or any of our licensors, distributors, suppliers or agents, and whether
arising prior to or after your agreement to this section, (collectively,
"Dispute(s)") will be governed by the procedure outlined below. </span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">YOU AND BABA ENTERTAINMENT
FURTHER AGREE THAT ANY ARBITRATION PURSUANT TO THIS SECTION SHALL NOT PROCEED
AS A CLASS, GROUP, OR REPRESENTATIVE ACTION. </span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Informal Dispute Resolution.&nbsp;</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;color:#040101">We
want to address your concerns without the need for a formal legal dispute.
Before filing a claim against us, you agree to try to resolve the Dispute informally
by contacting Customer Support via this form. Similarly, if you have provided
an email address to us as part of your account registration, we agree to do the
same. If a dispute is not resolved within 30 days after the email noting the
Dispute is sent, you or Baba Entertainment may initiate an arbitration
proceeding as described below.</span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">We Both Agree to Arbitrate.&nbsp;</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;color:#040101">By
agreeing to these Terms, and to the extent permitted by applicable law, you and
Baba Entertainment each and both agree to resolve any Disputes – including any
Dispute concerning the enforceability, validity, scope or severability of this
agreement to arbitrate – through final and binding arbitration as discussed
herein. </span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Opt-out of Agreement to Arbitrate.&nbsp;</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">You may decline this agreement to arbitrate by contacting support@babacasino.com within
30 days of first accepting these Terms and stating that you (include your first
and last name, email address and postal address) decline this arbitration
agreement. By opting out of the agreement to arbitrate, you will not be
precluded from playing the Games, but you and Baba Entertainment will not be
permitted to invoke the mutual agreement to arbitrate to resolve Disputes under
the Terms otherwise provided herein. </span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><b><span lang="EN-US" style="font-size:
12.0pt;color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Arbitration Procedures and Fees</span></b>.<span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">You and Baba Entertainment agree
that JAMS (“JAMS”) will administer the arbitration under its Streamlined Rules
in effort at the time arbitration is sought (“JAMS Rules”). Those rules are
available at </span><span lang="EN-US" style="color:black"><a href="http://www.jamsadr.com"><span style="font-size:12.0pt;,serif">www.jamsadr.com</span></a></span><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">.</span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">Arbitration will proceed on
an individual basis and will be handled by a sole arbitrator in accordance with
those rules; provided that the parties shall be presented with a list of five
potential arbitrators and shall rank those potential arbitrators in order of
preference. JAMS shall select the arbitrator with the highest combined
preference (e.g., if both parties select a potential arbitrator as their top
preference, that arbitrator will be selected). </span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">You and Baba Entertainment
further agree that, unless and only to the extent prohibited under JAMS Rules,
the arbitration will be held inTel Aviv/Israelor, at either your or our
election, will be conducted telephonically or via other remote electronic
means. The JAMS Rules will govern payment of all arbitration fees. </span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">The arbitrator shall be
authorized to award any remedies, including injunctive relief, that would be
available to you in an individual lawsuit and that are not waivable under
applicable law.</span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Batch Arbitration.&nbsp;</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;color:#040101">To
increase efficiency of resolution, in the event 25 or more similar arbitration
demands against Baba Entertainment, are presented by or with assistance of the
same law firm or organization or group of law firms or organizations working in
coordination, are submitted to JAMS in accordance with the rules described
above within a 30-day period, JAMS shall consolidate those arbitration as
contemplated in the JAMS Rules by</span></p>

  <p class="MsoListParagraphCxSpFirst" style="margin-bottom:9.0pt;text-indent:-18.0pt;
line-height:18.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif;color:#040101">(a)<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp; </span></span><span dir="LTR"></span><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">grouping the arbitration demands into batches of no more than 25
demands per batch (plus, to the extent there are fewer than 25 arbitration
demands left over after the batching described above, a final batch consisting
of the remaining demands); and</span></p>

  <p class="MsoListParagraphCxSpLast" style="margin-bottom:9.0pt;text-indent:-18.0pt;
line-height:18.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;,serif;color:#040101">(b)<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span dir="LTR"></span><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">providing for resolution of each batch as a single arbitration
with one set of filing and administrative fees and one arbitrator assigned per
batch. </span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">For avoidance of doubt,
consolidation does not require that all arbitration in a single batch be
decided the same, nor does it impair your right to present any evidence or
argument that you think particular to your case, so long as consistent with
JAMS Rules.</span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">You agree to cooperate in
good faith with Baba Entertainment and JAMS to implement such a batch approach
to resolution and fees.</span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Class Action and Collective Arbitration Waiver.&nbsp;</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">TO THE MAXIMUM EXTENT PERMITTED BY LAW, NEITHER YOU NOR BABA
ENTERTAINMENT SHALL BE ENTITLED: TO CONSOLIDATE, JOIN OR COORDINATE DISPUTES BY
OR AGAINST OTHER INDIVIDUALS OR ENTITIES; TO PARTICIPATE IN ANY GROUP, CLASS,
COLLECTIVE OR MASS ARBITRATION OR LITIGATION; TO ARBITRATE OR LITIGATE ANY
DISPUTE IN A REPRESENTATIVE CAPACITY, INCLUDING AS A REPRESENTATIVE MEMBER OF A
CLASS; TO ARBITRATE OR LITIGATE ANY DISPUTE IN A PRIVATE ATTORNEY GENERAL
CAPACITY; OR OTHERWISE TO SEEK TO RECOVER LOSSES OR DAMAGES (WHETHER FOR
YOURSELF OR OTHERS) INCURRED BY A THIRD PARTY. IN CONNECTION WITH ANY DISPUTE
(AS DEFINED ABOVE), ANY AND ALL SUCH RIGHTS ARE HEREBY EXPRESSLY AND
UNCONDITIONALLY WAIVED. NOTWITHSTANDING ANYTHING TO THE CONTRARY SET FORTH IN
THESE TERMS AND CONDITIONS, IN THE EVENT ALL OR ANY PORTION OF THIS SECTION&nbsp;
(DISPUTE RESOLUTION AND AGREEMENT TO ARBITRATE ON AN INDIVIDUAL BASIS) ARE
FOUND TO BE INVALID OR LESS THAN FULLY ENFORCEABLE IN A PARTICULAR DISPUTE,
THEN THE ENTIRETY OF THIS SECTION&nbsp; (DISPUTE RESOLUTION AND AGREEMENT TO
ARBITRATE ON AN INDIVIDUAL BASIS) MAY BE DEEMED VOID AND AS HAVING NO EFFECT
FOR PURPOSES OF THAT DISPUTE, UPON EITHER PARTY'S ELECTION.</span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Exceptions to Agreement to Arbitrate for Temporary Relief.&nbsp;</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">Notwithstanding the other provisions of this Section (Dispute
Resolution and Agreement to Arbitrate on an Individual Basis), either you or we
may bring an action in a court as authorized above for temporary injunctive
relief until an arbitrator has been empaneled and can determined whether to
continue, terminate, or modify such relief. </span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Confidentiality.&nbsp;</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;color:#040101">You
and Baba Entertainment shall maintain the confidential nature of the
arbitration proceedings and the arbitration award, including the arbitration
hearing, except as may be necessary to prepare for or conduct </span><span lang="EN" style="font-size:12.0pt;,serif;color:#040101">the
arbitration hearing on the merits, or except as may be necessary in connection
with a court application for a preliminary remedy, a judicial challenge to an
award or its enforcement, or unless otherwise required by law or judicial
decision. </span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><span lang="EN" style="font-size:12.0pt;
,serif;color:#040101">If any portion of this
section is determined by a court to be inapplicable or invalid, then the
remainder shall still be given full force and effect. </span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:21.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:19.0pt;,serif;
color:#A41EB4">GENERAL</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Assignment.&nbsp;</span></b><span lang="EN-US" style="font-size:
12.0pt;,serif;color:#040101">You may not assign or
transfer your rights and obligations under these Terms without our prior
written consent. Any attempted or actual assignment by you, without our prior
written consent, shall be null and void.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Tax.&nbsp;</span></b><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">You are solely responsible
for any taxes which apply to any prizes that you collect from your use of the
Services or App.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Governmental Compliance.&nbsp;</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;color:#040101">Our
performance of these Terms are subject to existing laws and legal process, and
nothing contained in these Terms is in derogation of our right to comply with
governmental, court, and law enforcement requests of requirements relating to
use of the Service or information provided to or gathered by the Company with
respect to such use. </span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Changes in ownership.&nbsp;</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;color:#040101">In
the event any change in the ownership or control, including but not limited to
a merger or acquisition, of Baba Entertainment, we may, upon notice to you and
without obtaining your consent, assign and delegate these Terms, including all
of our rights, performances, duties, liabilities and obligations contained
herein, to a third party.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Force Majeure.&nbsp;</span></b><span lang="EN-US" style="font-size:
12.0pt;,serif;color:#040101">We will not be liable
or responsible for any failure to perform, or delay in performance of, any of
our obligations under these Terms that are caused by events outside of our
reasonable control. </span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Severability.&nbsp;</span></b><span lang="EN-US" style="font-size:
12.0pt;,serif;color:#040101">If any provision of
these Terms is held to be illegal, invalid, or unenforceable by a competent
court, then the provision shall be performed and enforced to the maximum extent
permitted by law to reflect as closely as possible, the original intention of
that provision, and the remaining provisions of these Terms shall continue to
remain in full force and effect.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Interpretation.&nbsp;</span></b><span lang="EN-US" style="font-size:
12.0pt;,serif;color:#040101">The section headings
in these Terms are included for convenience only and shall take no part in the
interpretation or construction of these Terms. Whenever used in these Terms,
the term "Including", whether capitalized or not, means without
limitation to the preceding phrase. All examples and e.g. notations are
illustrative, not exhaustive.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Entire agreement.&nbsp;</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;color:#040101">These
Terms constitute the entire agreement between you and us concerning the subject
matter herein and supersede all prior and contemporaneous negotiations and oral
representations, agreements, and statements.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Waivers.&nbsp;</span></b><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">No waiver, concession,
extension, representation, alteration, addition or derogation from these Terms
by us, or pursuant to these Terms, will be effective unless consented to
explicitly and executed in writing by our authorized representative. Failure on
our part to demand performance of any provision in these Terms shall not
constitute a waiver of any of our rights under these Terms.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Relationship.&nbsp;</span></b><span lang="EN-US" style="font-size:
12.0pt;,serif;color:#040101">These Terms do not
create any agency, partnership, employment or fiduciary relationship between
you and us.</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><b><span lang="EN-US" style="font-size:12.0pt;
color:#0E76A8;border:none windowtext 1.0pt;
padding:0cm">Applicable Law and Jurisdiction.&nbsp;</span></b><span lang="EN-US" style="font-size:12.0pt;,serif;color:#040101">These
Terms your use of the Services and our entire relationship will be governed,
and interpreted in accordance with the laws of the State of Delaware
in the United States</span>,
without regard for its choice of conflict of law principles. The application of
the United Nations Convention on Contracts for the International Sale of Goods
is specifically excluded. Absent an express statement to the contrary, in the
event of any conflict or inconsistency between any provision of these Terms and
a provision of the <a href="https://www.baba-entertainment.com/play-responsible-play">Responsible Social Gameplay Policy</a>
or <a href="https://www.baba-entertainment.com/play-rules">Sweeps Rules</a>, these
Terms shall control solely to the extent necessary to resolve the conflict or
inconsistency.</p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline">Subject to the above, the parties agree
that any dispute, controversy, or claim arising out of or in connection with
these Terms, or the breach, termination or invalidity of these Terms and
Conditions, will be submitted exclusively to the courts in Delaware, and
you and we consent to the venue and personal jurisdiction of those courts.</p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;color:#040101">Notwithstanding the foregoing, the parties
agree that either party may move to compel arbitration or to enforce an
arbitral award issued hereunder before any court of competent jurisdiction.</span></p>

  <p class="MsoNormal" style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;
margin-left:0cm;line-height:21.0pt;background:white;vertical-align:baseline"><span lang="EN-US" style="font-size:19.0pt;,serif;
color:#A41EB4">CONTACT US</span></p>

  <p class="MsoNormal" style="margin-bottom:0cm;line-height:18.0pt;background:white;
vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;color:#040101">At any time, you may contact us with any
question, request, comment or complaint that you may have with respect to the
Service or these Terms, at:&nbsp;</span><span lang="EN-US" style="color:black"><a href="mailto:support@babacasino.com"><span style="font-size:12.0pt;,serif;
color:#007BFF;border:none windowtext 1.0pt;padding:0cm">support@babacasino.com</span></a></span><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">, or through our online contact form.</span></p>

  <p class="MsoNormal" style="margin-bottom:9.0pt;line-height:18.0pt;background:
white;vertical-align:baseline"><span lang="EN-US" style="font-size:12.0pt;
,serif;color:#040101">Effective Date: M</span><span lang="EN-US" style="font-size:12.0pt;,serif;
color:#040101">ay 1, 2024</span></p>

  <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

</div>
